import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { getEditableUserLabelsArray } from 'src/app/project/modules/share/share-utils/user-labels';
import { DefaultShareService } from '../../../../../../share/default-share.service';
import { EUserRole } from '../../../../../../share/share-utils/user-roles';
import { TShare } from '../../../../../../share/share.model';
import { USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK } from '../../../../../../user-management/user-management.consts';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-access',
  templateUrl: './account-user-modal-shares-list-share-access.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-access.component.scss'],
})
export class AccountUserModalSharesListShareAccessComponent implements DoCheck {
  @Input() ppShare: TShare;
  @Output() ppShareChange = new EventEmitter<TShare>();
  @Input() ppHideAsterisk: boolean = false;

  USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK = USER_MANAGEMENT_CUSTOMIZED_SHARE_MARK;
  index: number = null;

  shareOptions: TSelectOption[] = getEditableUserLabelsArray();

  constructor(private defaultShareService: DefaultShareService) {}

  ngDoCheck(): void {
    this.setItemIndex();
  }

  private setItemIndex(): void {
    this.index = this.shareOptions.findIndex((option) => option.value === this.ppShare.shareOption);
  }

  selectItem(item: TSelectOption): void {
    const newShare = { ...this.ppShare, shareOption: item.value as EUserRole };
    const newShareDTO = this.defaultShareService.resetShareToDefaults(newShare);

    this.ppShareChange.emit(newShareDTO);
  }

  checkIfShareIsDefault(): boolean {
    if (this.ppHideAsterisk) {
      return true;
    }

    return this.defaultShareService.checkIfShareIsDefault(this.ppShare);
  }
}
