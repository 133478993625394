import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { getDisabledUserManagementTooltipText } from 'src/app/project/modules/user-management/user-management-table-cells/utils/get-disabled-user-management-cell-tooltip-text';
import { TShare } from '../../../../../../share/share.model';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-activity',
  templateUrl: './account-user-modal-shares-list-share-activity.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-activity.component.scss'],
})
export class AccountUserModalSharesListShareActivityComponent implements DoCheck {
  @Input() ppShare: TShare;
  @Output() ppShareChange = new EventEmitter<TShare>();
  @Input() ppDisabled: boolean;
  @Input() ppShareOption: string;
  viewPermission: boolean;
  disabledTooltipText: string;

  ngDoCheck(): void {
    this.setViewPermission();
    this.disabledTooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
  }

  private setViewPermission(): void {
    this.viewPermission = this.ppShare.advancedAccessLevels.timeline.permission.read;
  }

  changeViewPermission(): void {
    this.viewPermission = !this.viewPermission;

    const newShare: TShare = { ...this.ppShare };
    newShare.advancedAccessLevels.timeline.permission.read = this.viewPermission;

    this.ppShareChange.emit(newShare);
  }
}
