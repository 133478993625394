<div class="pointCF__field pointCF__field--expandable">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_NUMBERS_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      class="pointCF__label-container"
      ppTitle="{{ ppFieldLabel }}"
      [ppTrimTooltip]="true"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <pp-custom-field-numeric-with-subfields
    [ppPointField]="ppField"
    [ppFieldId]="ppFieldId"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppCanEdit]="ppCanEdit"
    [ppPointId]="ppPointId"
    [ppNew]="ppNew"
    *ngIf="ppSubfieldsActive"
  ></pp-custom-field-numeric-with-subfields>

  <pp-custom-field-cost
    [ppWorkspaceId]="ppWorkspaceId"
    [ppPointId]="ppPointId"
    [ppFieldId]="ppFieldId"
    [ppFieldLabel]="ppFieldLabel"
    ppFieldCurrencySymbol=""
    [ppFieldCurrencyCode]="unit"
    [ppCurrencyName]="unitName"
    [ppFieldValue]="ppField.value"
    [ppIntegrationStatus]="EIntegrationStatus.OFF"
    [ppNew]="ppNew"
    [ppCanEdit]="ppCanEdit"
    (ppUpdate)="updateField($event)"
    [ppDecimalPlaces]="decimalPlaces"
    *ngIf="!ppSubfieldsActive"
  ></pp-custom-field-cost>
</div>
