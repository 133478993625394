<button
  class="addSubfieldButton"
  [class.addSubfieldButton--disabled]="ppDisabled"
  (click)="addSubfield()"
>
  <div class="addSubfieldButton__inner">
    <span class="addSubfieldButton__text">
      {{ addSubfieldText }}
    </span>
  </div>
</button>
