import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { IconModule } from '../icon/icon-module';
import { CalculatorIconComponent } from './calculator-icon.component';

@NgModule({
  declarations: [CalculatorIconComponent],
  imports: [CommonModule, PipesModule, IconModule, TooltipsModule],
  exports: [CalculatorIconComponent],
})
export class CalculatorIconModule {}
