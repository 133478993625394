import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { checkClearShortcut } from '../../../utils/check-clear-shortcut';

@Component({
  selector: 'pp-custom-field-subfield-description-input',
  templateUrl: './custom-field-subfield-description-input.component.html',
  styleUrl: './custom-field-subfield-description-input.component.scss',
})
export class CustomFieldSubfieldDescriptionInputComponent {
  @ViewChild('input') inputElement: ElementRef;
  @Input() ppValue: string;
  @Output() ppValueChange = new EventEmitter<string>();
  @Input() ppCanEdit: boolean;
  @Input() ppFocused: boolean;
  @Output() ppFocusedChange = new EventEmitter<boolean>();
  @Output() ppDescriptionChange = new EventEmitter<string>();

  focused = false;

  onBlur(): void {
    this.focused = false;
    this.ppFocusedChange.emit(false);
  }

  onFocus(): void {
    if (!this.ppCanEdit) {
      return;
    }

    this.focused = true;
    this.ppFocusedChange.emit(true);
  }

  onKeydown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());

    event.stopPropagation();
    event.stopImmediatePropagation();

    if (event.key === 'Enter') {
      this.blurInput(event);
    }
  }

  updateField(): void {
    this.ppValueChange.emit(this.ppValue);
  }

  clearCustomField(): void {
    this.ppValueChange.emit('');
  }

  onDescriptionChange(event: Event): void {
    this.ppDescriptionChange.emit(this.ppValue);
  }

  onKeyUp(event: KeyboardEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
  }

  private blurInput(event: Event): void {
    this.inputElement.nativeElement.blur();
  }
}
