import { TColumn } from '../../site/site-table/columns/column.model';
import { ECustomFieldType } from '../custom-field-types-enums';
import { GET_CUSTOM_FIELDS } from '../custom-fields.store';
import { CombineCustomFieldsService } from './combine-custom-fields.service';

export function compareCustomFieldColumns(customField1: TColumn, customField2: TColumn): boolean {
  if (!customField1?.customFieldIds || !customField2?.customFieldIds) {
    return false;
  }

  const isFormula =
    GET_CUSTOM_FIELDS()[customField1.customFieldIds[0]]?.type === ECustomFieldType.FORMULA;

  const formulasLoaded = CombineCustomFieldsService.getGroupedFormulas().length > 0;

  if (formulasLoaded && isFormula) {
    return CombineCustomFieldsService.compareByFormula(
      CombineCustomFieldsService.getGroupedFormulas(),
      customField1.customFieldIds?.[0],
      customField2.customFieldIds?.[0],
    );
  } else {
    return (
      customField1.name === customField2.name &&
      customField1.currency === customField2.currency &&
      customField1.decimalPlaces === customField2.decimalPlaces &&
      !!customField1.showTotal === !!customField2.showTotal &&
      customField1.unit === customField2.unit &&
      customField1.subValuesActive === customField2.subValuesActive
    );
  }
}
