<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img class="pointCF__bulk-changes-field-icon" [src]="EIconPath.CUSTOM_FIELD_COST_ACTIVITY" />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <pp-custom-field-cost
    ppTooltip
    [ppTitle]="
      ppCustomField.subValuesActive
        ? ('prompt_bulk_changes_subfields_edit_disabled' | ppTranslate)
        : ''
    "
    [ppCanEdit]="!ppCustomField.subValuesActive"
    [ppWorkspaceId]="ppCustomField.workspaceId"
    [ppFieldId]="ppCustomField.id"
    [ppFieldLabel]="ppCustomField.label"
    [ppFieldCurrencySymbol]="ppCustomField.currencySymbol"
    [ppFieldCurrencyCode]="ppCustomField.currencyCode"
    [ppCurrencyName]="currencyName"
    [ppFieldValue]="'' + (ppCustomFields[ppFieldId] ? ppCustomFields[ppFieldId] : '')"
    [ppNew]="false"
    [ppDecimalPlaces]="2"
    (ppUpdate)="updateCost($event, ppCustomField)"
  ></pp-custom-field-cost>
</div>
