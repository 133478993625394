import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { getDecimalPlacesPlaceholder } from '../../../custom-field-numbers/utils/getDecimalPlacesPlaceholder';
import { checkClearShortcut } from '../../../utils/check-clear-shortcut';

@Component({
  selector: 'pp-custom-field-subfield-number-input',
  templateUrl: './custom-field-subfield-number-input.component.html',
  styleUrl: './custom-field-subfield-number-input.component.scss',
})
export class CustomFieldSubfieldNumberInputComponent {
  @Input() ppValue: string;
  @Output() ppValueChange = new EventEmitter<string>();
  @Input() ppCanEdit: boolean;
  @Input() ppUnit: string;
  @Input() ppFocused: boolean;
  @Input() ppDecimalPlaces: number;
  @Input() ppUnitTooltip: string;
  @Output() ppFocusedChange = new EventEmitter<boolean>();
  @Output() ppInputChange = new EventEmitter<string>();
  @ViewChild('subfieldValue', { static: false }) costInputElement: ElementRef;

  private readonly destroy$ = new Subject<void>();

  focused = false;
  EIconPath = EIconPath;
  autonumericOptions: TAutoNumericOptions;
  placeholder: string;

  ngOnChanges() {
    this.placeholder = getDecimalPlacesPlaceholder(this.ppDecimalPlaces);

    const decimalsCorrect =
      !isNaN(this.ppDecimalPlaces) && typeof this.ppDecimalPlaces === 'number';
    const numberOfDecimalPlaces: number = decimalsCorrect ? this.ppDecimalPlaces : 2;

    this.autonumericOptions = {
      formulaMode: true,
      watchExternalChanges: true,
      decimalPlaces: numberOfDecimalPlaces,
      modifyValueOnWheel: false,
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateField(value: string): void {
    this.ppValue = value !== null ? value : null;

    this.ppValueChange.emit(this.ppValue);
  }

  onBlur(): void {
    this.ppFocusedChange.emit(false);
    this.focused = false;
  }

  onValueChange(event): void {
    this.ppInputChange.next(this.ppValue);
  }

  onFocus(): void {
    if (!this.ppCanEdit) {
      return;
    }

    this.ppFocusedChange.emit(true);
    this.focused = true;
  }

  blurInput(event: Event): void {
    const target = event.target as HTMLElement;

    target.blur();
  }

  onKeyDown(event: KeyboardEvent): void {
    checkClearShortcut(event, () => this.clearCustomField());
  }

  private clearCustomField(): void {
    this.ppValueChange.emit(null);
  }
}
