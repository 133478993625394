<div class="integrationField">
  <pp-toggle
    [ppChecked]="ppField.active"
    ppStyle="filter"
    [ppDisabled]="ppDisabled"
    [ppToggleError]="toggleError"
    (ppAction)="toggleField(ppField)"
  ></pp-toggle>

  <span class="integrationField__name">
    <img [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_BIG" />

    {{ ppField.fieldName }}

    <pp-icon
      ppTooltip
      [ppTitle]="tooltipText"
      [ppSanitize]="false"
      [ppSrc]="EIconPath.ICON_BADGE_INFO"
      ppClass="setSize14"
      ppColor="inherit"
    ></pp-icon>
  </span>
  <div class="integrationField__selectContainer">
    <pp-select
      *ngIf="ppField.subList"
      [ppItems]="ppField.subList"
      [ppNoSearchBar]="true"
      class="integrationField__select"
    >
      <ng-container selected>
        <span class="accountSettings__select-placeholder">{{ 'view_list' | ppTranslate }}</span>
      </ng-container>
    </pp-select>
  </div>
</div>
