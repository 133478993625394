export function getFullUnitName(shortName: string): string {
  switch (shortName) {
    // Length
    case 'mm':
      return 'millimetre';
    case 'cm':
      return 'centimetre';
    case 'm':
      return 'metre';
    case 'km':
      return 'kilometre';
    case 'in':
      return 'inch';
    case 'ft':
      return 'foot';
    case 'yd':
      return 'yard';
    case 'mi':
      return 'mile';
    case 'nm':
      return 'nanometre';
    case 'µm':
      return 'micrometre';
    case 'Å':
      return 'angstrom';

    // Area
    case 'mm²':
      return 'square millimetre';
    case 'cm²':
      return 'square centimetre';
    case 'm²':
      return 'square metre';
    case 'km²':
      return 'square kilometre';
    case 'in²':
      return 'square inch';
    case 'ft²':
      return 'square foot';
    case 'acre':
      return 'acre';

    // Volume
    case 'ml':
      return 'millilitre';
    case 'l':
      return 'litre';
    case 'cl':
      return 'centilitre';
    case 'µl':
      return 'microlitre';
    case 'cc':
      return 'cubic centimetre';
    case 'm³':
      return 'cubic metre';
    case 'dm³':
      return 'cubic decimetre';
    case 'fl oz':
      return 'fluid ounce';

    // Weight/Mass
    case 'mg':
      return 'milligramme';
    case 'g':
      return 'gramme';
    case 'kg':
      return 'kilogramme';
    case 't':
      return 'tonne';
    case 'oz':
      return 'ounce';
    case 'lb':
      return 'pound';
    case 'st':
      return 'stone';
    case 'ct':
      return 'carat';

    // Temperature
    case '°C':
      return 'degrees Celsius';
    case '°F':
      return 'degrees Fahrenheit';
    case 'K':
      return 'kelvin';
    case 'R':
      return 'Rankine';

    // Time
    case 's':
      return 'second';
    case 'ms':
      return 'millisecond';
    case 'µs':
      return 'microsecond';
    case 'ns':
      return 'nanosecond';
    case 'min':
      return 'minute';
    case 'h':
      return 'hour';

    // Speed
    case 'm/s':
      return 'metres per second';
    case 'km/h':
      return 'kilometres per hour';
    case 'mph':
      return 'miles per hour';
    case 'cm/s':
      return 'centimetres per second';
    case 'kn':
      return 'knot';

    // Energy
    case 'J':
      return 'joule';
    case 'kJ':
      return 'kilojoule';
    case 'cal':
      return 'calorie';
    case 'kcal':
      return 'kilocalorie';
    case 'Wh':
      return 'watt-hour';
    case 'kWh':
      return 'kilowatt-hour';
    case 'eV':
      return 'electronvolt';
    case 'BTU':
      return 'British thermal unit';
    case 'ft-lbf':
      return 'foot-pound force';

    // Power
    case 'W':
      return 'watt';
    case 'kW':
      return 'kilowatt';
    case 'MW':
      return 'megawatt';
    case 'GW':
      return 'gigawatt';
    case 'hp':
      return 'horsepower';

    // Pressure
    case 'Pa':
      return 'pascal';
    case 'atm':
      return 'atmosphere';
    case 'psi':
      return 'pound per square inch';
    case 'mmHg':
      return 'millimetres of mercury';
    case 'bar':
      return 'bar';
    case 'Torr':
      return 'torr';

    // Electrical
    case 'A':
      return 'ampere';
    case 'mA':
      return 'milliampere';
    case 'V':
      return 'volt';
    case 'kV':
      return 'kilovolt';
    case 'Ω':
      return 'ohm';
    case 'mΩ':
      return 'milliohm';
    case 'µF':
      return 'microfarad';

    // Others
    case 'rpm':
      return 'revolutions per minute';
    case 'lux':
      return 'lux';
    case 'lm':
      return 'lumen';
    case 'cd':
      return 'candela';
    case 'mol':
      return 'mole';
    case 'Hz':
      return 'hertz';

    default:
      return shortName;
  }
}
