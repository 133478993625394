import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { locationOriginFactory } from '../../../core/services/window/locationFactory';
import { CustomFieldsModule } from '../custom-fields/custom-fields.module';
import { PlanModule } from '../plan/plan.module';
import { TagsModule } from '../tags/tags.module';
import { AttachmentsModule } from './attachments/attachments.module';
import { pointsReducer } from './points-reducer/points.reducer';

import { PointsGenerateService } from './point-generate/points-generate.service';
import { PointsDetailsService } from './points-details.service';
import { PointsFetchingService } from './points-fetching.service';
import { PointsHelperService } from './points-helper.service';
import { PointsUpdateService } from './points-update.service';
import { PointsUsersService } from './points-users.service';
import { PointsService } from './points.service';

// Modals
import { EditPointService } from './point-full-modal/edit-point.service';
import { PointFullModalComponent } from './point-full-modal/point-full-modal.component';
import { PointHalfModalComponent } from './point-half-modal/point-half-modal.component';
import { PointHalfModalService } from './point-half-modal/point-half-modal.service';
import { PointModalService } from './point-modal/point-modal.service';

// Modal elements
import { PointActionBarOptionsDropdownComponent } from './point-modal/point-action-bar/point-action-bar-options-dropdown/point-action-bar-options-dropdown.component';
import { PointActionBarComponent } from './point-modal/point-action-bar/point-action-bar.component';
import { PointAttachmentsDeleteService } from './point-modal/point-attachments/point-attachments-delete.service';
import { PointAttachmentsComponent } from './point-modal/point-attachments/point-attachments.component';
import { PointAttachmentsService } from './point-modal/point-attachments/point-attachments.service';
import { PointCommentInputComponent } from './point-modal/point-comment-input/point-comment-input.component';
import { PointDescriptionComponent } from './point-modal/point-description/point-description.component';
import { PointFieldsComponent } from './point-modal/point-fields/point-fields.component';
import { PointFieldsService } from './point-modal/point-fields/point-fields.service';
import { PointHeaderComponent } from './point-modal/point-header/point-header.component';
import { PointPlanComponent } from './point-modal/point-plan/point-plan.component';
import { PointPlanService } from './point-modal/point-plan/point-plan.service';
import { ActivityChangeComponent } from './point-modal/point-timeline/activity-change/activity-change.component';
import { PointActivityComponent } from './point-modal/point-timeline/point-activity.component';
import { PointActivityService } from './point-modal/point-timeline/point-activity.service';
import { PointPriorityDropdownComponent } from './point-priority/point-priority-dropdown/point-priority-dropdown.component';
import { PointSeenByComponent } from './point-seen-by/point-seen-by.component';
import { PointStatusDropdownComponent } from './point-status/point-status-dropdown/point-status-dropdown.component';
// Modal layout
import { PointContainerComponent } from './point-modal/layout/point-container/point-container.component';
import { PointHeadingComponent } from './point-modal/layout/point-heading/point-heading.component';
// Export
// Bulk changes
import { PointBulkAssigneesModalComponent } from './point-modal/point-assignees/point-bulk-assignees-modal/point-bulk-assignees-modal.component';
import { PointBulkPriorityModalComponent } from './point-priority/point-bulk-priority-modal/point-bulk-priority-modal.component';
import { PointBulkStatusModalComponent } from './point-status/point-bulk-status-modal/point-bulk-status-modal.component';
// Close
import { ClosePointModalComponent } from './close-point-modal/close-point-modal.component';
import { UnsavedFilesModalComponent } from './unsaved-files-modal/unsaved-files-modal.component';
// Delete
import { DeletePointModalComponent } from './delete-point-modal/delete-point-modal.component';
import { DeleteFilesModalComponent } from './point-modal/point-attachments/delete-files-modal/delete-files-modal.component';

import { DescriptionActivityComponent } from './point-modal/point-timeline/activity-change/description-activity/description-activity.component';

// Custom fields
import { PointAssigneesComponent } from './point-modal/point-assignees/point-assignees.component';
import { PointCreatedByComponent } from './point-modal/point-created-by/point-created-by.component';
import { PointFieldsCheckboxComponent } from './point-modal/point-fields/point-fields-checkbox/point-fields-checkbox.component';
import { PointFieldsCostComponent } from './point-modal/point-fields/point-fields-cost/point-fields-cost.component';
import { PointFieldsDateComponent } from './point-modal/point-fields/point-fields-date/point-fields-date.component';
import { PointFieldsListComponent } from './point-modal/point-fields/point-fields-list/point-fields-list.component';
import { PointFieldsNumbersComponent } from './point-modal/point-fields/point-fields-numbers/point-fields-numbers.component';
import { PointFieldsPercentageComponent } from './point-modal/point-fields/point-fields-percentage/point-fields-percentage.component';
import { PointFieldsRichTextComponent } from './point-modal/point-fields/point-fields-rich-text/point-fields-rich-text.component';
import { PointFieldsTextComponent } from './point-modal/point-fields/point-fields-text/point-fields-text.component';
import { PointFieldsTimeComponent } from './point-modal/point-fields/point-fields-time/point-fields-time.component';
import { PointFieldsTimelineComponent } from './point-modal/point-fields/point-fields-timeline/point-fields-timeline.component';

// DOCUMENTS
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { ButtonModule } from '../../components/button/button.module';
import { CalculatorIconModule } from '../../components/calculator-icon/calculator-icon.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { DatepickerModule } from '../../components/input/datepicker/datepicker.module';
import { RadioModule } from '../../components/input/radio/radio.module';
import { RichTextModule } from '../../components/input/rich-text/rich-text.module';
import { TimeInputModule } from '../../components/input/time-input/time-input.module';
import { UploadModule } from '../../components/input/upload/upload.module';
import { ModalModule } from '../../components/modal/modal.module';
import { ProgressBarModule } from '../../components/progress-bar/progress-bar.module';
import { SaveIndicatorModule } from '../../components/save-indicator/save-indicator.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { TagModule } from '../../components/tag/tag.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { PlaceholdersModule } from '../placeholders/placeholders.module';
import { PointBulkFlagModalComponent } from './point-flag-modal/point-flag-modal.component';
import { PointGalleryComponent } from './point-gallery/point-gallery.component';
import { PointModalHeaderComponent } from './point-modal-header/point-modal-header.component';
import { PointOptionsComponent } from './point-modal/layout/point-options/point-options.component';
import { PointDocumentsComponent } from './point-modal/point-attachments/point-documents/point-documents.component';
import { PointDocumentsService } from './point-modal/point-attachments/point-documents/point-documents.service';
import { RenameFileModalComponent } from './point-modal/point-attachments/rename-file-modal/rename-file-modal.component';
import { PointFieldsFormulaComponent } from './point-modal/point-fields/point-fields-formula/point-fields-formula.component';
import { PointFieldsTagsComponent } from './point-modal/point-fields/point-fields-tags/point-fields-tags.component';
import { PointPlanClearAllModalComponent } from './point-modal/point-plan/point-plan-clear-all-modal/point-plan-clear-all-modal.component';
import { AttachmentAddedActivityComponent } from './point-modal/point-timeline/activity-change/attachment-added-activity/attachment-added-activity.component';
import { AttachmentDeletedActivityComponent } from './point-modal/point-timeline/activity-change/attachment-deleted-activity/attachment-deleted-activity.component';
import { CommentDeleteDropdownComponent } from './point-modal/point-timeline/comment-delete-dropdown/comment-delete-dropdown.component';
import { DeleteCommentModalComponent } from './point-modal/point-timeline/delete-comment-modal/delete-comment-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild([
      // {
      //   path: 'point',
      // },
    ]),
    StoreModule.forFeature('points', pointsReducer),
    // EffectsModule.forRoot([PointsEffect]),
    ButtonModule,
    RichTextModule,
    RadioModule,
    DatepickerModule,
    CheckboxModule,
    PlanModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ProgressBarModule,
    UploadModule,
    CustomFieldsModule,
    SpinnerModule,
    SaveIndicatorModule,
    TagModule,
    TagsModule,
    SaveIndicatorModule,
    AttachmentsModule,
    PipesModule,
    ModalModule,
    SecureSrcModule,
    IconModule,
    PlaceholdersModule,
    TimeInputModule,
    CalculatorIconModule,
  ],
  exports: [
    // Modals
    PointFullModalComponent,
    PointHalfModalComponent,
    // Documents,
    PointDocumentsComponent,
  ],
  providers: [
    { provide: 'ORIGIN', useFactory: locationOriginFactory },
    PointsService,
    PointsHelperService,
    PointsUpdateService,
    PointsGenerateService,
    PointsFetchingService,
    PointsDetailsService,
    PointsUsersService,
    // Modals
    PointModalService,
    EditPointService,
    PointHalfModalService,
    // Modal elements
    PointFieldsService,
    PointAttachmentsService,
    PointDocumentsService,
    PointAttachmentsDeleteService,
    PointPlanService,
    PointActivityService,
  ],
  declarations: [
    // Modals
    PointFullModalComponent,
    PointHalfModalComponent,
    // Modal elements
    PointPriorityDropdownComponent,
    PointStatusDropdownComponent,
    PointActionBarComponent,
    PointHeaderComponent,
    PointHeadingComponent,
    PointFieldsComponent,
    PointDescriptionComponent,
    PointPlanComponent,
    PointAttachmentsComponent,
    PointActivityComponent,
    ActivityChangeComponent,
    PointCommentInputComponent,
    PointActionBarOptionsDropdownComponent,
    PointSeenByComponent,
    // Modal layout
    PointContainerComponent,
    // Bulk changes
    PointBulkStatusModalComponent,
    PointBulkFlagModalComponent,
    PointBulkPriorityModalComponent,
    PointBulkAssigneesModalComponent,
    // Close
    ClosePointModalComponent,
    UnsavedFilesModalComponent,
    // Delete
    DeletePointModalComponent,
    DeleteFilesModalComponent,
    CommentDeleteDropdownComponent,
    DeleteCommentModalComponent,
    DescriptionActivityComponent,
    // Custom fields
    PointFieldsTextComponent,
    PointFieldsCostComponent,
    PointFieldsListComponent,
    PointFieldsDateComponent,
    PointCreatedByComponent,
    PointFieldsPercentageComponent,
    PointFieldsTimelineComponent,
    PointFieldsCheckboxComponent,
    PointFieldsRichTextComponent,
    PointFieldsNumbersComponent,
    PointFieldsTimeComponent,
    // Assignees
    PointAssigneesComponent,
    // Documents,
    PointDocumentsComponent,
    PointGalleryComponent,
    PointPlanClearAllModalComponent,
    RenameFileModalComponent,
    AttachmentAddedActivityComponent,
    AttachmentDeletedActivityComponent,
    PointModalHeaderComponent,
    PointFieldsTagsComponent,
    PointOptionsComponent,
    PointFieldsFormulaComponent,
  ],
})
export class PointsModule {}
