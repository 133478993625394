<div class="editAccountUserShareList">
  <section
    class="editAccountUserShareList__back"
    (click)="goBack()"
    *ngIf="!editingUsers && !ppEmailsReadOnly"
  >
    <pp-icon
      [ppSrc]="EIconPath.ICON_LEFT_MENU_ARROW_BACK"
      class="editAccountUserShareList__backArrow"
      ppColor="primary-600"
      ppClass="setSize18"
    ></pp-icon>
    <span class="editAccountUserShareList__backText">{{
      'back_to_user_&_site_selection' | ppTranslate
    }}</span>
  </section>

  <pp-add-users-error-box *ngIf="ppShowingError"></pp-add-users-error-box>
  <pp-add-users-warning-box *ngIf="warningMessages?.length > 0"></pp-add-users-warning-box>
  <pp-account-user-modal-edit-warning *ngIf="ppShowInfoBox"></pp-account-user-modal-edit-warning>

  <section class="editAccountUserShareList__users">
    <h6 class="editAccountUserShareList__usersHeader">{{ 'users' | ppTranslate }}</h6>

    <pp-share-edit-email-input
      class="editAccountUserShareList__usersInput"
      [(ppEmailList)]="emailList"
      [ppDisabled]="ppEmailsReadOnly"
      (ppEmailListChange)="setEmailList($event)"
    ></pp-share-edit-email-input>
  </section>

  <section class="editAccountUserShareList__shareHeaders">
    <div class="sharesHeaders__site">{{ 'site' | ppTranslate }}</div>
    <div class="sharesHeaders__access">
      {{ 'access' | ppTranslate }}
      <i
        ppTooltip
        [ppMobileTooltip]="true"
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppSanitize]="false"
        ppTitle="
      <div style='text-align: left'>
        <ul style='padding-left: 0; margin: 0'>
          <li>•	<strong>{{ 'guest' | ppTranslate }}</strong> – {{
          'share_guest_description' | ppTranslate
        }}</li>
          <br/>
          <li>•	<strong>{{ 'normal' | ppTranslate }}</strong> – {{
          'share_normal_description' | ppTranslate
        }}</li>
          <br/>
          <li>•	<strong>{{ 'admin' | ppTranslate }}</strong> – {{
          'share_admin_description' | ppTranslate
        }}</li>
        </ul>
      </div>"
      ></i>
    </div>
    <div class="sharesHeaders__accessibleTags">
      {{ 'accessible_tags' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'tags_limited_description' | ppTranslate"
        [ppMobileTooltip]="true"
      ></i>
    </div>
    <div class="sharesHeaders__tags">
      {{ 'tags' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'user_management_tags_AAL_description' | ppTranslate"
        [ppMobileTooltip]="true"
      ></i>
    </div>
    <div class="sharesHeaders__activity">
      {{ 'activity' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'activity_AAL_description' | ppTranslate"
        [ppMobileTooltip]="true"
      ></i>
    </div>
    <div class="sharesHeaders__exports">
      {{ 'exports' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'export_AAL_description' | ppTranslate"
        [ppMobileTooltip]="true"
      ></i>
    </div>
    <div>
      {{ 'comments' | ppTranslate }}
      <i
        ppTooltip
        [ppMobileTooltip]="true"
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppSanitize]="false"
        ppTitle="
      <div style='text-align: left'>
        <ul style='padding-left: 0; margin: 0'>
          <li>•	<strong>{{ 'hide_comments' | ppTranslate }}</strong> – {{
          'hide_comments_description' | ppTranslate
        }}</li>
          <li>•	<strong>{{ 'view_comments' | ppTranslate }}</strong> – {{
          'view_comments_description' | ppTranslate
        }}</li>
          <li>•	<strong>{{ 'make_comments' | ppTranslate }}</strong> – {{
          'make_comments_description' | ppTranslate
        }}</li>
        </ul>
      </div>"
      ></i>
    </div>
    <div class="sharesHeaders__customFields_width">
      {{ 'custom_fields' | ppTranslate }}
      <i
        ppTooltip
        [ppOptions]="{ placement: 'right' }"
        class="fa fa-info-circle settingsSection__header-info"
        aria-hidden="true"
        [ppTitle]="'custom_field_list_description' | ppTranslate"
        [ppMobileTooltip]="true"
      ></i>
    </div>
  </section>

  <section class="editAccountUserShareList__shares">
    <pp-account-user-modal-shares-list-bulk-share></pp-account-user-modal-shares-list-bulk-share>

    <ng-container *ngFor="let workspace of selectedWorkspaces">
      <pp-account-user-modal-shares-list-share
        [ppHasError]="workspace.hasError"
        [ppWorkspaceId]="workspace.workspaceId"
        *ngIf="!editingUsers || ppSelectedWorkspaceIds.includes(workspace.workspaceId)"
        (ppShowCustomFieldsTable)="showCustomFieldsTable(workspace.workspaceId)"
      ></pp-account-user-modal-shares-list-share>
    </ng-container>
  </section>
</div>
