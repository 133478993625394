export const getDecimalPlacesPlaceholder = (decimalPlaces: number): string => {
  let placeholder = '00';

  if (decimalPlaces > 0) {
    placeholder += '.';
    placeholder += Array(decimalPlaces).fill('0').join('');
  }

  return placeholder;
};
