import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TShare } from 'src/app/project/modules/share/share.model';
import { TUser } from 'src/app/project/modules/user/user.model';
import { EditSiteNameModalComponent } from '../edit-site-name-modal/edit-site-name-modal.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PreviousPageService } from 'src/app/core/services/previous-page.service';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { GET_SIDE_PANEL_SERVICE } from 'src/app/project/modules/layout/side-panel/side-panel-store';
import { SidePanelService } from 'src/app/project/modules/layout/side-panel/side-panel.service';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { GET_SHARE } from 'src/app/project/modules/share/shares.store';
import { UserService } from 'src/app/project/modules/user/user.service';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSiteList,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { EInstance } from 'src/app/project/shared/enums/instances';
import { ScreenService } from '../../../../core/services/window/screen.service';
import { ModalService } from '../../../components/modal/modal.service';
import { EIconPath } from '../../../shared/enums/icons.enum';
import { ArchiveSitesService } from '../../account/account-settings/account-settings-pages/account-settings-sites/archive-sites.service';
import { EUserType } from '../../users/user-types-enum';
import { WorkspaceService } from '../../workspace/workspace.service';
import { DeleteSiteModalComponent } from '../delete-site-modal/delete-site-modal.component';
import { TDeleteSiteModalData } from '../delete-site-modal/delete-site-modal.model';
import { ESiteSettingsPaths } from '../site-routes.consts';
import { TSiteSettingsDropdownData } from './site-settings-dropdown.model';

@Component({
  selector: 'pp-site-settings-dropdown',
  templateUrl: './site-settings-dropdown.component.html',
  styleUrls: ['./site-settings-dropdown.component.scss'],
})
export class SiteSettingsDropdownComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  user: TUser;
  dropdown: TDropdown<TSiteSettingsDropdownData>;
  share: TShare;
  private sidePanelService: SidePanelService;
  private dropdownWorkspaceId: string;
  showDeleteOption: boolean;

  ESiteSettingsPaths = ESiteSettingsPaths;
  EUserRole = EUserRole;
  EIconPath = EIconPath;
  deleteHover = false;
  integrationsEnabled: boolean;

  constructor(
    @Inject('HOSTNAME') private host: string,
    private dropdownService: DropdownService,
    private modalService: ModalService,
    private router: Router,
    private userService: UserService,
    private screenService: ScreenService,
    private workspaceService: WorkspaceService,
    private previousPageService: PreviousPageService,
    private archiveSitesService: ArchiveSitesService,
  ) {}

  ngOnInit() {
    this.dropdown = this.dropdownService.getDropdown();
    this.sidePanelService = GET_SIDE_PANEL_SERVICE();

    if (!this.dropdown.data) {
      throw new Error('Data for this dropdown is required');
    }

    const dropdownData: TSiteSettingsDropdownData = this.dropdown.data;

    this.dropdownWorkspaceId = dropdownData.workspaceId;

    this.user = this.userService.getUser();
    this.share = GET_SHARE(this.dropdownWorkspaceId);
    this.showDeleteOption = !dropdownData.fromPanel && dropdownData.fromSite;

    const isDeveloper =
      this.user.userType === EUserType.DEVELOPER || this.user.userType === EUserType.TESTER;
    this.integrationsEnabled = isDeveloper || this.host !== EInstance.ASM;

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.dropdownService.hideDropdown();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  hideDropdown(linkType: ESiteSettingsPaths = null): void {
    if (linkType) {
      this.previousPageService.setPreviousSiteUrl(this.router.url);
      this.router.navigate(['/settings/site/', this.dropdownWorkspaceId, linkType]);

      logEventInGTAG(EGoogleEventSiteList.SITE_LIST_SITE_SETTINGS, {
        event_category: EGoogleEventCategory.SITE_LIST,
        event_details: linkType,
      });
    }

    if (this.dropdown.callback) {
      this.dropdown.callback(linkType);
    }

    this.dropdownService.hideDropdown();
    const { isDesktop } = this.screenService.getCurrentState();
    if (!isDesktop) {
      this.sidePanelService.collapsePanel();
    }
  }

  editSiteName(): void {
    logEventInGTAG(EGoogleEventSiteList.SITE_LIST_SITE_SETTINGS, {
      event_category: EGoogleEventCategory.SITE_LIST,
      event_details: 'SITE_NAME',
    });
    this.modalService.setData(this.dropdownWorkspaceId);
    this.modalService.showModal(EditSiteNameModalComponent);

    this.dropdownService.hideDropdown();
  }

  deleteSite(): void {
    logEventInGTAG(EGoogleEventSiteList.SITE_LIST_SITE_SETTINGS, {
      event_category: EGoogleEventCategory.SITE_LIST,
      event_details: 'DELETE',
    });

    const modalData: TDeleteSiteModalData = {
      workspaceId: this.dropdownWorkspaceId,
    };

    this.modalService.setData(modalData);
    this.modalService.showModal(DeleteSiteModalComponent);

    this.dropdownService.hideDropdown();
  }

  onDeleteHover(hover: boolean): void {
    this.deleteHover = hover;
  }
}
