<div class="dashletActivity">
  <ng-container [ngSwitch]="type">
    <img
      *ngSwitchCase="customFieldTypes.PERCENTAGE"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.NUMBERS"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY"
    />
    <img
      *ngSwitchCase="customFieldTypes.COST"
      class="dashletActivity__icon"
      [src]="EIconPath.CUSTOM_FIELD_COST_ACTIVITY"
    />
    <img
      *ngSwitchCase="EIntegrationStatus.ON"
      class="dashletActivity__icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL"
    />

    <img
      *ngSwitchCase="EIntegrationStatus.DISABLED"
      class="dashletActivity__icon"
      [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL"
    />
  </ng-container>

  <div class="dashletActivity__text">
    <ng-container>
      <span class="dashletActivities__item-quoted timeline__strong">{{ ppChange.label }}</span>
      {{ 'subfield' | ppTranslate }}

      <span *ngIf="ppChange.newValue && !ppChange.oldValue"
        >{{ 'added' | ppTranslate }}
        <span class="dashletActivities__item-quoted"> {{ ppChange.newValue }}</span>
      </span>
      <span *ngIf="ppChange.newValue && ppChange.oldValue">
        {{ 'updated_to' | ppTranslate }}
        <span class="dashletActivities__item-quoted">{{ ppChange.newValue }}</span></span
      >
      <span *ngIf="!ppChange.newValue && ppChange.oldValue"
        ><span class="dashletActivities__item-quoted">{{ ppChange.newValue }}</span>
        {{ 'deleted' | ppTranslate }}</span
      >
    </ng-container>
  </div>
</div>
