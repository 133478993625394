<!-- ASSIGNEES -->
<pp-point-assignees
  [ppSelectedAssignees]="ppAssignees"
  (ppOnChange)="updateAssignees($event)"
  [ppWorkspaceId]="ppWorkspaceId"
  [ppDisabled]="!ppCanEdit"
></pp-point-assignees>

<ng-container *ngIf="workspaces">
  <pp-point-fields-tags
    [ppPointId]="ppPointId"
    [ppTags]="ppTags"
    [ppWorkspaceId]="ppWorkspaceId"
    [ppNew]="ppNew"
  ></pp-point-fields-tags>

  <div *ngFor="let fieldId of readableCustomFields">
    <ng-container [ngSwitch]="customFields[ppWorkspaceId][fieldId].type">
      <pp-point-fields-text
        *ngSwitchCase="ECustomFieldType.TEXT"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-text>

      <pp-point-fields-date
        *ngSwitchCase="ECustomFieldType.DATE"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppFieldValue]="pointCustomFields[fieldId]?.value"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-date>

      <pp-point-fields-list
        *ngSwitchCase="ECustomFieldType.LIST"
        [ppField]="customFields[ppWorkspaceId][fieldId]"
        [ppFieldId]="fieldId"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppFieldValue]="pointCustomFields[fieldId]?.value"
        [ppFieldValueId]="pointCustomFields[fieldId]?.idOfChosenElement"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
        [ppVolyActive]="customFields[ppWorkspaceId][fieldId]?.volyIntegrationActive"
      ></pp-point-fields-list>

      <pp-point-fields-cost
        *ngSwitchCase="ECustomFieldType.COST"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppFieldCurrencySymbol]="customFields[ppWorkspaceId][fieldId]?.currencySymbol"
        [ppFieldCurrencyCode]="customFields[ppWorkspaceId][fieldId]?.currencyCode"
        [ppVolyActive]="customFields[ppWorkspaceId][fieldId]?.volyIntegrationActive"
        [ppPointCustomField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
        [ppSubfieldsActive]="customFields[ppWorkspaceId][fieldId]?.subValuesActive"
      ></pp-point-fields-cost>

      <pp-point-fields-percentage
        *ngSwitchCase="ECustomFieldType.PERCENTAGE"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
        [ppSubfieldsActive]="customFields[ppWorkspaceId][fieldId]?.subValuesActive"
      ></pp-point-fields-percentage>

      <pp-point-fields-formula
        *ngSwitchCase="ECustomFieldType.FORMULA"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppFieldCurrencySymbol]="customFields[ppWorkspaceId][fieldId]?.currencySymbol"
        [ppFieldCurrencyCode]="customFields[ppWorkspaceId][fieldId]?.currencyCode"
        [ppNew]="ppNew"
      ></pp-point-fields-formula>

      <pp-point-fields-timeline
        *ngSwitchCase="ECustomFieldType.TIMELINE"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-timeline>

      <pp-point-fields-time
        *ngSwitchCase="ECustomFieldType.TIME"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-time>

      <pp-point-fields-numbers
        *ngSwitchCase="ECustomFieldType.NUMBERS"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
        [ppSubfieldsActive]="customFields[ppWorkspaceId][fieldId]?.subValuesActive"
      ></pp-point-fields-numbers>

      <pp-point-fields-rich-text
        *ngSwitchCase="ECustomFieldType.RICHTEXT"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppRichText]="pointCustomFields[fieldId]?.value"
        [ppPlainText]="pointCustomFields[fieldId]?.plainText"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-rich-text>

      <pp-point-fields-checkbox
        *ngSwitchCase="ECustomFieldType.CHECKBOX"
        [ppFieldId]="customFields[ppWorkspaceId][fieldId]?.id"
        [ppFieldLabel]="customFields[ppWorkspaceId][fieldId]?.label"
        [ppValue]="pointCustomFields[fieldId]?.value"
        [ppField]="pointCustomFields[fieldId]"
        [ppWorkspaceId]="ppWorkspaceId"
        [ppPointId]="ppPointId"
        [ppNew]="ppNew"
        [ppCanEdit]="editableCustomFields.includes(fieldId) || allFieldsEditable"
      ></pp-point-fields-checkbox>
    </ng-container>
  </div>
</ng-container>
