import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  private readonly hubspotUrl =
    'https://yachting.volygroup.com/pinpoint-works-login-voly-projects-advert';

  constructor(private domSanitizer: DomSanitizer) {}

  getHubspotURL(): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.hubspotUrl);
  }
}
