<!-- Generic (status, priority, custom fields) -->
<span class="activityChange__icon" *ngIf="ppChange.label === 'Priority'">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_PRIORITY" />
</span>

<span class="activityChange__icon" *ngIf="ppChange.label === 'Status'">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_STATUS" />
</span>

<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.ASSIGNEES">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_ASSIGNEES" />
</span>

<span
  class="activityChange__icon"
  *ngIf="
    ppChange.propName === EActivityPropName.POINT_COPY ||
    ppChange.propName === EActivityPropName.POINT_COPY_FROM ||
    ppChange.propName === EActivityPropName.POINT_COPY_TO
  "
>
  <img
    class="activityChange__icon-svg activityChange__icon--copy"
    [src]="EIconPath.BASIC_FIELD_COPY"
  />
</span>

<span
  class="activityChange__icon"
  *ngIf="
    ppChange.propName === EActivityPropName.POINT_MOVE ||
    ppChange.propName === EActivityPropName.POINT_MOVE_FROM ||
    ppChange.propName === EActivityPropName.POINT_MOVE_TO
  "
>
  <img
    class="activityChange__icon-svg activityChange__icon--move"
    [src]="EIconPath.POINT_ACTIVITY_MOVE"
  />
</span>

<ng-container [ngSwitch]="type">
  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.TEXT">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_TEXT_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.LIST">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_LIST_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.CHECKBOX">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_CHECKBOX_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.DATE">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_DATE_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.TIME">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_TIME_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.COST">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_COST_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.NUMBERS">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_NUMBERS_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.PERCENTAGE">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.TIMELINE">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_TIMELINE_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="ECustomFieldType.RICHTEXT">
    <img class="activityChange__icon-svg" [src]="EIconPath.CUSTOM_FIELD_RICH_TEXT_ACTIVITY" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="EIntegrationStatus.DISABLED">
    <img class="activityChange__icon-svg" [src]="EIconPath.ICON_INTEGRATION_VOLY_INACTIVE_SMALL" />
  </span>

  <span class="activityChange__icon" *ngSwitchCase="EIntegrationStatus.ON">
    <img class="activityChange__icon-svg" [src]="EIconPath.ICON_INTEGRATION_VOLY_ACTIVE_SMALL" />
  </span>
</ng-container>

<div
  class="activityChange"
  *ngIf="
    ppChange.generic &&
    ppChange.propName !== EActivityPropName.ADDITIONAL_PINS &&
    ppChange.propName !== EActivityPropName.POLYGONS &&
    ppChange.propName !== EActivityPropName.PINS &&
    ppChange.propName !== EActivityPropName.CUSTOM_FIELD_SUB_VALUE
  "
>
  <span *ngIf="ppChange.oldValue || ppChange.newValue">
    <span
      *ngIf="
        ppChange.propName === EActivityPropName.POINT_MOVE ||
        ppChange.propName === EActivityPropName.POINT_MOVE_FROM ||
        ppChange.propName === EActivityPropName.POINT_MOVE_TO
      "
      >{{ 'moved_this_point' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.label }}</strong>
    </span>

    <span
      *ngIf="
        ppChange.propName === EActivityPropName.POINT_COPY ||
        ppChange.propName === EActivityPropName.POINT_COPY_FROM ||
        ppChange.propName === EActivityPropName.POINT_COPY_TO
      "
      >{{ 'copied_this_point' | ppTranslate }}
      <strong class="timeline__strong">{{ ppChange.label }}</strong>
    </span>

    <span
      *ngIf="
        ppChange.propName !== EActivityPropName.POINT_COPY &&
        ppChange.propName !== EActivityPropName.POINT_COPY_FROM &&
        ppChange.propName !== EActivityPropName.POINT_COPY_TO &&
        ppChange.propName !== EActivityPropName.POINT_MOVE_FROM &&
        ppChange.propName !== EActivityPropName.POINT_MOVE_TO &&
        ppChange.propName !== EActivityPropName.POINT_MOVE
      "
    >
      <span *ngIf="!(ppChange.oldValue && !ppChange.newValue)">Changed </span>

      <span class="customField__label">
        <strong
          class="timeline__strong customField__label--text"
          ppTooltip
          [ppTrimTooltip]="true"
          [ppTitle]="ppChange.label"
        >
          {{ ppChange.label }}
        </strong>
      </span>
    </span>
  </span>

  <span
    *ngIf="
      ((ppChange.propName === EActivityPropName.POINT_MOVE ||
        ppChange.propName === EActivityPropName.POINT_COPY) &&
        ppChange.oldValue) ||
      ppChange.propName === EActivityPropName.POINT_MOVE_TO ||
      ppChange.propName === EActivityPropName.POINT_COPY_TO ||
      ppChange.propName === EActivityPropName.POINT_MOVE_FROM ||
      ppChange.propName === EActivityPropName.POINT_COPY_FROM
    "
  >
    {{ 'from' | ppTranslate }} <strong class="timeline__strong">{{ ppChange.oldValue }}</strong>
  </span>

  <span *ngIf="ppChange.oldValue && !ppChange.newValue"> {{ 'was_cleared' | ppTranslate }} </span>

  <span *ngIf="ppChange.oldValue && ppChange.newValue">
    {{ 'to' | ppTranslate | lowercase }}
    <strong
      class="timeline__strong activityChange--{{ statusPriorityToColor(stringChange.newValue) }}"
      >{{ humanizeActivityChange(stringChange.newValue) }}.</strong
    >
  </span>

  <span *ngIf="!ppChange.oldValue && ppChange.newValue">
    {{ 'to' | ppTranslate | lowercase }}
    <strong
      class="timeline__strong"
      [ngClass]="{ 'timeline__no-wrap': type === 'DATE' || type === 'TIMELINE' }"
      >{{ ppChange.newValue }}.</strong
    >
  </span>
</div>

<ng-container
  *ngIf="
    ppChange.propName === EActivityPropName.ADDITIONAL_PINS ||
    ppChange.propName === EActivityPropName.POLYGONS ||
    ppChange.propName === EActivityPropName.PINS
  "
>
  <span class="activityChange__icon">
    <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_LOCATION" />
  </span>

  <div class="activityChange">{{ 'changed_location' | ppTranslate }}.</div>
</ng-container>

<!-- Location -->
<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.PIN">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_LOCATION" />
</span>

<div class="activityChange" *ngIf="ppChange.propName === EActivityPropName.PIN">
  <ng-container *ngIf="ppChange.oldValue && !ppChange.newValue">
    {{ 'location_removed' | ppTranslate }}.
  </ng-container>

  <ng-container *ngIf="!(ppChange.oldValue && !ppChange.newValue)">
    {{ 'changed_location' | ppTranslate }}.
  </ng-container>
</div>

<!-- Title -->
<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.TITLE">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_TITLE" />
</span>

<div class="activityChange" *ngIf="ppChange.propName === EActivityPropName.TITLE">
  {{ 'changed' | ppTranslate }} <strong class="timeline__strong"> Title </strong> to
  <strong class="timeline__strong">{{ ppChange.newValue }}.</strong>
</div>

<!--Subfields-->
<span class="activityChange" *ngIf="ppChange.propName === EActivityPropName.CUSTOM_FIELD_SUB_VALUE">
  <strong class="timeline__strong" ppTooltip [ppTrimTooltip]="true" [ppTitle]="ppChange.label">
    {{ ppChange.label }}
  </strong>

  <span> {{ 'subfield' | ppTranslate }} </span>

  <span *ngIf="ppChange.newValue && !ppChange.oldValue"
    >{{ 'added' | ppTranslate }}
    <span class="activityChange__item-quoted"> {{ ppChange.newValue }}</span>
  </span>
  <span *ngIf="ppChange.newValue && ppChange.oldValue">
    {{ 'updated_to' | ppTranslate }}
    <span class="activityChange__item-quoted">{{ ppChange.newValue }}</span></span
  >
  <span *ngIf="!ppChange.newValue && ppChange.oldValue"
    ><span class="activityChange__item-quoted">{{ ppChange.newValue }}</span>
    {{ 'deleted' | ppTranslate }}</span
  >
</span>

<!-- Red Flag -->
<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.FLAGGED">
  <pp-icon
    class="activityChange__icon-svg"
    [ppSrc]="EIconPath.ICON_ACTIVITIES_RED_FLAG_14"
    ppClass="setSize14"
    ppColor="grey-500"
    *ngIf="!ppChange.newValue"
  />
  <img
    class="activityChange__icon-svg"
    [src]="EIconPath.ICON_ACTIVITIES_RED_FLAG_ACTIVATED_14"
    *ngIf="ppChange.newValue"
  />
</span>

<div class="activityChange" *ngIf="ppChange.propName === EActivityPropName.FLAGGED">
  <ng-container *ngIf="ppChange.newValue">
    {{ 'point_flagged' | ppTranslate }}
  </ng-container>

  <ng-container *ngIf="!ppChange.newValue">
    {{ 'point_unflagged' | ppTranslate }}
  </ng-container>
</div>

<!-- Description -->
<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.DESCRIPTION">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_DESCRIPTION" />
</span>

<div
  class="activityChange"
  *ngIf="ppChange.propName === EActivityPropName.DESCRIPTION && ppChange.newValue"
>
  {{ 'changed' | ppTranslate }} <strong class="timeline__strong">Description</strong> to

  <pp-description-activity [ppPlainText]="stringChange.newValue"></pp-description-activity>
</div>

<div
  class="activityChange"
  *ngIf="ppChange.propName === EActivityPropName.DESCRIPTION && !ppChange.newValue"
>
  <strong class="timeline__strong">Description</strong> was cleared.
</div>

<span class="activityChange__icon" *ngIf="ppChange.propName === EActivityPropName.DESCRIPTION_RICH">
  <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_DESCRIPTION" />
</span>

<div class="activityChange" *ngIf="ppChange.propName === EActivityPropName.DESCRIPTION_RICH">
  {{ 'changed' | ppTranslate }} <strong class="timeline__strong">Description</strong> to:
  <pp-description-activity [ppDescription]="stringChange.newValue"></pp-description-activity>
</div>

<div>
  <!-- Added tags -->
  <div class="activityChange--tag">
    <span
      class="activityChange__icon"
      *ngIf="ppChange.propName === EActivityPropName.TAGS && stringsChange.added.length"
    >
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_TAGS" />
    </span>

    <div
      class="activityChange activityChange--taller"
      *ngIf="ppChange.propName === EActivityPropName.TAGS && stringsChange.added.length"
    >
      <span class="activityChange__tag-description"> {{ 'added' | ppTranslate }} </span>

      <span class="activityChange__tag" *ngFor="let value of stringsChange.added">
        <pp-tag [ppText]="value" [ppDeletable]="false" [ppDisabled]="true"></pp-tag>
      </span>
    </div>
  </div>

  <!-- Removed tags -->
  <div class="activityChange--tag">
    <span
      class="activityChange__icon"
      *ngIf="ppChange.propName === EActivityPropName.TAGS && ppChange.deleted.length"
    >
      <img class="activityChange__icon-svg" [src]="EIconPath.BASIC_FIELD_TAGS" />
    </span>

    <div
      class="activityChange activityChange--taller"
      *ngIf="ppChange.propName === EActivityPropName.TAGS && ppChange.deleted.length"
    >
      <span class="activityChange__tag-description"> {{ 'removed' | ppTranslate }} </span>

      <span class="activityChange__tag" *ngFor="let value of stringsChange.deleted">
        <pp-tag [ppText]="value" [ppDeletable]="false" [ppDisabled]="true"></pp-tag>
      </span>
    </div>
  </div>
</div>

<!-- Changed rich text CF -->

<div class="activityChange" *ngIf="type === 'RICHTEXT'">
  <span *ngIf="ppChange.oldValue && !ppChange.newValue">
    <span class="customField__label">
      <strong
        class="timeline__strong customField__label--text"
        ppTooltip
        [ppTrimTooltip]="true"
        [ppTitle]="ppChange.label"
      >
        {{ ppChange.label }}
      </strong>
    </span>
    {{ 'was_cleared' | ppTranslate }}.
  </span>

  <span *ngIf="ppChange.newValue">
    {{ 'changed' | ppTranslate }}
    <span class="customField__label">
      <strong
        class="timeline__strong customField__label--text"
        ppTooltip
        [ppTrimTooltip]="true"
        [ppTitle]="ppChange.label"
      >
        {{ ppChange.label }}
      </strong>
    </span>
    {{ 'to' | ppTranslate | lowercase }}:
  </span>

  <pp-description-activity
    *ngIf="stringChange.newValue"
    [ppDescription]="stringChange.newValue"
  ></pp-description-activity>
</div>

<!-- Added assignees -->

<div
  class="activityChange activityChange--taller"
  *ngIf="stringsChange.propName === EActivityPropName.ASSIGNEES && stringsChange.added.length"
>
  <span class="activityChange__assignees-label">{{ 'added' | ppTranslate }}</span>

  <span
    class="activityChange__assignee"
    *ngFor="let userId of stringsChange.added; let last = last"
  >
    <ng-container *ngIf="allUsers[userId]">
      <img
        ppImage
        [src]="allUsers[userId].avatarPublicUrl"
        *ngIf="allUsers[userId].avatarPublicUrl"
        class="activityChange__assignee--avatar"
      />

      <img
        class="activityChange__assignee--avatar"
        *ngIf="!allUsers[userId].avatarPublicUrl"
        [src]="EIconPath.BASIC_FIELD_ASSIGNEES"
      />

      <span class="activityChange__assignee--userName">
        {{ allUsers[userId].userName }}<span *ngIf="!last">,</span>
        <span *ngIf="last">.</span>
      </span>
    </ng-container>

    <ng-container *ngIf="!allUsers[userId]">
      - ({{ 'deleted_user' | ppTranslate }})<span *ngIf="!last">,</span>
      <span *ngIf="last">.</span>
    </ng-container>
  </span>
</div>

<!-- Removed assignees -->
<div
  class="activityChange activityChange--taller"
  *ngIf="ppChange.propName === EActivityPropName.ASSIGNEES && stringsChange.deleted.length"
>
  <span class="activityChange__assignees-label">{{ 'removed' | ppTranslate }}</span>

  <span
    class="activityChange__assignee"
    *ngFor="let userId of stringsChange.deleted; let last = last"
  >
    <ng-container *ngIf="allUsers[userId]">
      <img
        ppImage
        [src]="allUsers[userId].avatarPublicUrl"
        *ngIf="allUsers[userId].avatarPublicUrl"
        class="activityChange__assignee--avatar"
      />

      <img
        class="activityChange__assignee--avatar"
        *ngIf="!allUsers[userId].avatarPublicUrl"
        [src]="EIconPath.BASIC_FIELD_ASSIGNEES"
      />

      <span class="activityChange__assignee--userName">
        {{ allUsers[userId].userName }}<span *ngIf="!last">,</span>
        <span *ngIf="last">.</span>
      </span>
    </ng-container>

    <ng-container class="activityChange__assignee--userName" *ngIf="!allUsers[userId]">
      - ({{ 'deleted_user' | ppTranslate }})<span *ngIf="!last">,</span>
      <span *ngIf="last">.</span>
    </ng-container>
  </span>
</div>

<!-- Added file, 360 images, video, photos -->
<pp-attachment-added-activity
  (ppOpenGallery)="openGallery($event)"
  [ppMediaAttachments]="media.attachments"
  [ppChange]="ppChange"
  *ngIf="
    (ppChange.propName === EActivityPropName.DOCUMENTS ||
      ppChange.propName === EActivityPropName.IMAGES_360 ||
      ppChange.propName === EActivityPropName.VIDEOS ||
      ppChange.propName === EActivityPropName.IMAGES) &&
    (ppChange.added.length || ppChange.filesChange === 'ADDED')
  "
></pp-attachment-added-activity>

<!-- Deleted file, 360 images, video, photos -->
<pp-attachment-deleted-activity
  [ppChange]="ppChange"
  *ngIf="
    (ppChange.propName === EActivityPropName.DOCUMENTS ||
      ppChange.propName === EActivityPropName.IMAGES_360 ||
      ppChange.propName === EActivityPropName.VIDEOS ||
      ppChange.propName === EActivityPropName.IMAGES) &&
    (ppChange.deleted.length || ppChange.filesChange === 'DELETED')
  "
></pp-attachment-deleted-activity>
