import { TPointsByWorkspace } from '../points.model';
import { TUpdatePointCustomFieldPayload } from './points-reducer.consts';

export function updateStorePointCustomField(
  state: TPointsByWorkspace,
  payload: TUpdatePointCustomFieldPayload,
): TPointsByWorkspace {
  let pointIndex = null;

  if (state[payload.workspaceId]) {
    pointIndex = state[payload.workspaceId].entities.findIndex(
      (_point) => _point._id === payload.pointId,
    );
    const point = state[payload.workspaceId].entities[pointIndex];

    if (point) {
      point.header.updatedEpochMillis = new Date().getTime();
      point.customFieldsMap[payload.customFieldId].value = payload.customFieldValue;

      if (payload.customFieldValueId) {
        point.customFieldsMap[payload.customFieldId].idOfChosenElement = payload.customFieldValueId;
      }

      if (payload.plainText) {
        point.customFieldsMap[payload.customFieldId].plainText = payload.plainText;
      }

      if (payload.mentions) {
        point.customFieldsMap[payload.customFieldId].mentions = payload.mentions;
      }

      if (payload.subfields) {
        point.customFieldsMap[payload.customFieldId].subValues = payload.subfields;
      }

      state[payload.workspaceId].entities.splice(pointIndex, 1, point);
    }
  }

  return state;
}
