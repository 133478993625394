<div class="subfieldTotal">
  <div class="subfieldTotal__inner">
    <section
      ppTooltip
      [ppTitle]="ppUnitTooltip"
      class="customFieldNumeric__unitWrapper"
      [class.subfieldTotal__unit--hidden]="!ppUnit"
    >
      <span class="customFieldNumeric__unit">
        {{ ppUnit }}
      </span>
    </section>

    <section class="subfieldTotal__value" [class.subfieldTotal__value--noUnit]="!ppUnit">
      <div class="subfieldTotal__value--inner">{{ formattedValue }}</div>

      <div
        class="subfieldTotal__value--inner subfieldTotal__value--placeholder"
        *ngIf="!formattedValue"
      >
        {{ placeholder }}
      </div>
    </section>
  </div>
  <div class="subfieldTotal__title">{{ 'total' | ppTranslate }}</div>
</div>
