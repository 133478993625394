import { Injectable } from '@angular/core';
import { AccountApiProviderService } from '@core/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ResponseErrorService } from '../../errors/response-error.service';
import { TAccountUsage, TAccountUsageResponse } from '../account-usage/account-usage.consts';

@Injectable({
  providedIn: 'root',
})
export class AccountUsageService {
  private accountUsage: {
    [accountId: string]: TAccountUsage;
  } = {};

  constructor(
    private accountApiProviderService: AccountApiProviderService,
    private responseErrorService: ResponseErrorService,
  ) {}

  fetchAccountUsage(accountId: string, refetch = false): Observable<TAccountUsage> {
    if (!refetch && this.accountUsage[accountId]) {
      return of(this.accountUsage[accountId]);
    }

    return this.accountApiProviderService.fetchAccountUsage(accountId).pipe(
      map((usage) => {
        const accountUsage: TAccountUsage = {};

        usage.forEach((workspace) => {
          accountUsage[workspace.workspaceId] = {
            pointCount: workspace.pointCount,
            totalBytes: workspace.totalBytes,
            userCount: workspace.userCount,
            archived: workspace.archived,
            siteName: workspace.siteName,
            workspaceId: workspace.workspaceId,
          };
        });

        this.accountUsage[accountId] = accountUsage;

        return accountUsage;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  clearUsage(): void {
    this.accountUsage = {};
  }

  updateSiteData(accountId: string, workspaces: TAccountUsageResponse): void {
    workspaces.forEach((workspace) => {
      if (!this.accountUsage[accountId]?.[workspace.workspaceId]) {
        return;
      }

      this.accountUsage[accountId][workspace.workspaceId] = {
        pointCount: workspace.pointCount,
        totalBytes: workspace.totalBytes,
        userCount: workspace.userCount,
        archived: workspace.archived,
        siteName: workspace.siteName,
        workspaceId: workspace.workspaceId,
      };
    });
  }
}
