import { TPointCustomField } from '@project/view-models';
import { transformDate } from 'src/app/project/shared/date-transformer';
import { ECustomFieldType } from '../../custom-fields/custom-field-types-enums';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { GET_CUSTOM_FIELDS } from '../../custom-fields/custom-fields.store';
import { TPoint } from '../../points/points.model';
import { GET_PREFERENCES } from '../../preferences/preferences.store';
import { TColumn } from '../../site/site-table/columns/column.model';
import { checkPointTimelineField } from './check-point-timeline-field';
import { formatCustomFieldValue } from './format-custom-field-value';

export function fillCustomFieldValue({
  point,
  column,
  customFieldsService,
  newArray,
  exportType,
}: {
  point: TPoint;
  column: TColumn & { timeline?: boolean };
  customFieldsService: CustomFieldsService;
  newArray: (string | number)[];
  exportType: string;
}): string {
  let value = '';

  const pointCustomField = findCustomField(point, column);

  column.timeline = checkPointTimelineField(column, customFieldsService);

  if (pointCustomField) {
    value = pointCustomField.value;

    const customField = GET_CUSTOM_FIELDS()[pointCustomField.customFieldTemplateId];

    //uses the newArray
    if (customField.type === ECustomFieldType.TIMELINE) {
      value = formatTimelineFieldValue(pointCustomField, newArray);
    } else {
      //None newArray values
      value = formatCustomFieldValue(pointCustomField, exportType);
    }
  } else {
    if (column.timeline) {
      newArray.push('-');
    }

    value = '-';
  }

  return value;
}
function formatTimelineFieldValue(customField: TPointCustomField, newArray: (string | number)[]) {
  const preferences = GET_PREFERENCES();

  if (customField.value) {
    const dates = customField.value.split('~');

    newArray.push(
      transformDate({
        value: dates[0],
        inputHourFormat: '',
        format: preferences.dateFormat,
        localTime: false,
      }),
    );

    return transformDate({
      value: dates[1],
      inputHourFormat: '',
      format: preferences.dateFormat,
      localTime: false,
    });
  } else {
    newArray.push('');
    return '';
  }
}

function findCustomField(
  point: TPoint,
  column: TColumn & { timeline?: boolean },
): TPointCustomField {
  const customFieldIndex = findCustomFieldIndex(point, column);
  const customField = point.customFieldsMap[customFieldIndex];
  return customField;
}

function findCustomFieldIndex(point: TPoint, column: TColumn & { timeline?: boolean }): string {
  for (const key in point.customFieldsMap) {
    const customField = GET_CUSTOM_FIELDS()[key];

    if (customField.label === column.name) {
      return key;
    }
  }
}
