import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TAccount } from 'src/app/project/modules/account/account.model';
import { TPointsByWorkspace } from 'src/app/project/modules/points/points.model';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';

import { TimelineGroupingDropdownComponent } from './timeline-grouping-dropdown/timeline-grouping-dropdown.component';

import { OfflineService } from 'src/app/project/modules/offline/offline.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { DropdownService } from '../../../components/dropdown/dropdown-service/dropdown.service';
import { ModalService } from '../../../components/modal/modal.service';
import { PointHalfModalService } from '../../points/point-half-modal/point-half-modal.service';
import { SiteDataService, TSiteData } from '../site-data.service';

import { ScreenService } from '@core/services';
import { TPreferences } from 'src/app/project/modules/preferences/preferences.model';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EIconPath } from '../../../shared/enums/icons.enum';
import Timeline from '../site-timeline/Timeline';
import { GET_TIMELINE } from '../site-timeline/timeline.ui.store';

@Component({
  selector: 'pp-timeline-options',
  templateUrl: './timeline-options.component.html',
  styleUrls: ['./timeline-options.component.scss'],
})
export class TimelineOptionsComponent implements OnInit, OnDestroy {
  @Input() ppDataFetched = false;
  @Input() ppIsOverview = false;

  private site: TSiteData = this.siteDataService.getSite();

  private workspaceId: string = null;
  workspaces$: Observable<TWorkspacesById>;
  workspace: TWorkspace;
  private offline$: Observable<boolean>;
  private offline = false;
  private account: TAccount;
  groupingHovered = false;
  groupingDropdownVisible = false;

  public sitePlanExists = true;
  globalSitePreferences = false;
  preferences: TPreferences;
  EUserRole = EUserRole;
  EIconPath = EIconPath;

  private readonly destroy$ = new Subject<void>();
  private dropdown: TDropdown = this.dropdownService.getDropdown();

  private points$: Observable<TPointsByWorkspace>;
  private accounts$: Observable<TAccount[]>;
  private accounts: TAccount[];
  private preferences$: Observable<TPreferences>;
  timeline: Timeline;

  constructor(
    private store: Store<{
      workspaces: TWorkspacesById;
      points: TPointsByWorkspace;
      offline: boolean;
      preferences: TPreferences;
      accounts: TAccount[];
    }>,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private pointHalfModalService: PointHalfModalService,
    private siteDataService: SiteDataService,
    private activeService: ActiveService,
    private offlineService: OfflineService,
    private dropdownService: DropdownService,
    private screenService: ScreenService,
  ) {
    this.workspaces$ = this.store.pipe(select(EStore.WORKSPACES));
    this.points$ = this.store.pipe(select(EStore.POINTS));
    this.offline$ = this.store.pipe(select(EStore.OFFLINE));
    this.accounts$ = this.store.pipe(select(EStore.ACCOUNTS));
    this.preferences$ = this.store.pipe(select(EStore.PREFERENCES));
  }

  ngOnInit() {
    this.preferences$.pipe(takeUntil(this.destroy$)).subscribe((preferences) => {
      this.preferences = preferences;
    });

    this.activatedRoute.paramMap.subscribe((params) => {
      this.workspaceId = params.get('id');
    });

    this.accounts$.pipe(takeUntil(this.destroy$)).subscribe((accounts) => {
      this.accounts = accounts;

      this.checkAccounts();
    });

    this.workspaces$.pipe(takeUntil(this.destroy$)).subscribe((workspaces) => {
      this.sitePlanExists = !!workspaces?.[this.workspaceId]?.sitePlan;

      if (workspaces[this.workspaceId] && workspaces[this.workspaceId].loadedFullWorkspace) {
        this.workspace = workspaces[this.workspaceId];

        const { isDesktop } = this.screenService.getCurrentState();

        if (isDesktop && !this.sitePlanExists) {
          this.site.sitePlanVisible = false;
        }

        if (!this.sitePlanExists) {
          this.pointHalfModalService.hideModal();
        }
      } else if (!this.workspaceId) {
        this.sitePlanExists = false;
      }

      this.checkAccounts();
    });

    this.points$.pipe(takeUntil(this.destroy$)).subscribe((pointsStore) => {
      this.timeline = GET_TIMELINE();
    });

    this.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      this.offline = offline;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkAccounts(): void {
    const accountId = this.activeService.getActiveAccountId();

    if (accountId && Array.isArray(this.accounts) && this.accounts.length) {
      this.account = this.accounts.find((account) => account.accountId === accountId);

      if (
        this.account &&
        this.account.accountFeatures &&
        typeof this.account.accountFeatures.globalSitePreferences !== 'undefined'
      ) {
        this.globalSitePreferences = this.account.accountFeatures.globalSitePreferences;
      } else {
        this.globalSitePreferences = false;
      }
    }
  }

  toggleGroupingDropdown(buttonId: string): void {
    if (!this.ppDataFetched || this.offline) {
      return;
    }

    if (!this.offline) {
      if (this.dropdown.visible && this.dropdown.buttonId === buttonId) {
        this.groupingDropdownVisible = false;
        this.dropdownService.hideDropdown();
      } else {
        this.groupingDropdownVisible = true;

        this.dropdownService.showDropdown(buttonId, TimelineGroupingDropdownComponent, {
          suppressScrollbar: true,

          onClose: () => {
            this.groupingDropdownVisible = false;
          },
        });
      }
    }
  }

  //
  // More dropdown
  //

  groupingHover(): void {
    this.groupingHovered = true;
  }

  groupingHoverEnd(): void {
    this.groupingHovered = false;
  }
}
