import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { TAutoNumericOptions } from 'src/app/core/helpers/create-autonumeric';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { CustomFieldsService } from '../custom-fields.service';

@Component({
  selector: 'pp-custom-field-time',
  templateUrl: './custom-field-time.component.html',
  styleUrls: ['./custom-field-time.component.scss'],
})
export class CustomFieldTimeComponent {
  @Input() ppField: TPointCustomField;
  @Input() ppReadOnly = false;
  @Input() ppUpdating: boolean | string = false;
  @Input() ppWorkspaceId: string;
  @Output() ppUpdate = new EventEmitter();

  @ViewChild('daysInput', { static: false }) daysInputElement: ElementRef;
  @ViewChild('hoursInput', { static: false }) hoursInputElement: ElementRef;
  @ViewChild('minutesInput', { static: false }) minutesInputElement: ElementRef;

  constructor(private customFieldsService: CustomFieldsService) {}

  days: string;
  hours: string;
  minutes: string;
  showHoursOnly: boolean;

  autonumericOptions: TAutoNumericOptions = {
    modifyValueOnWheel: false,
    minimumValue: '-999',
    allowDecimalPadding: false,
    maximumValue: '999',
    formulaMode: true,
    watchExternalChanges: true,
  };

  ngOnChanges(): void {
    const customField = this.customFieldsService.getWorkspaceCustomField(
      this.ppWorkspaceId,
      this.ppField.customFieldTemplateId,
    );

    this.showHoursOnly = customField?.showHoursOnly;
  }

  updateField(): void {
    if (this.daysInputElement) {
      this.daysInputElement.nativeElement.blur();
    }

    this.hoursInputElement.nativeElement.blur();
    this.minutesInputElement.nativeElement.blur();

    if (this.ppUpdate) {
      const days = +this.days ? this.days.replace(/,/g, '') : '0';
      const hours = +this.hours ? this.hours.replace(/,/g, '') : '0';
      const minutes = +this.minutes ? this.minutes.replace(/,/g, '') : '0';

      const value = +hours * 3.6e6 + +minutes * 60000 + +days * 8.64e7;

      this.ppUpdate.emit(value);
    }
  }

  updateHoursInput(value: string): void {
    this.hours = value;

    this.updateField();
  }

  updateMinutesInput(value: string): void {
    this.minutes = value;

    this.updateField();
  }

  updateDaysInput(value: string): void {
    this.days = value;

    this.updateField();
  }

  blurInput(event: Event) {
    blurInput(event.target);
  }
}
