<div *ngFor="let change of ppActivity.data.changeList; let index = index">
  <pp-dashlet-activity-images
    *ngIf="change.propName === propNames.IMAGES"
    [ppChange]="change"
  ></pp-dashlet-activity-images>

  <pp-dashlet-activity-images360
    *ngIf="change.propName === propNames.IMAGES360"
    [ppChange]="change"
  ></pp-dashlet-activity-images360>

  <pp-dashlet-activity-videos
    *ngIf="change.propName === propNames.VIDEOS"
    [ppChange]="change"
  ></pp-dashlet-activity-videos>

  <pp-dashlet-activity-documents
    *ngIf="change.propName === propNames.DOCUMENTS"
    [ppChange]="change"
  ></pp-dashlet-activity-documents>

  <pp-dashlet-activity-status
    *ngIf="change.propName === propNames.STATUS"
    [ppChange]="change"
  ></pp-dashlet-activity-status>

  <pp-dashlet-activity-priority
    *ngIf="change.propName === propNames.PRIORITY"
    [ppChange]="change"
  ></pp-dashlet-activity-priority>

  <pp-dashlet-activity-description
    *ngIf="change.propName === propNames.DESCRIPTION && !shouldBeHidden"
    [ppChange]="change"
  ></pp-dashlet-activity-description>

  <pp-dashlet-activity-description-rich
    *ngIf="change.propName === propNames.DESCRIPTION_RICH && change.newValue"
    [ppChange]="change"
  ></pp-dashlet-activity-description-rich>

  <pp-dashlet-activity-rich-text
    *ngIf="change.richText === true"
    [ppChange]="change"
  ></pp-dashlet-activity-rich-text>

  <pp-dashlet-activity-title
    *ngIf="change.propName === propNames.TITLE"
    [ppChange]="change"
  ></pp-dashlet-activity-title>

  <pp-dashlet-activity-tags
    *ngIf="change.propName === propNames.TAGS"
    [ppChange]="change"
  ></pp-dashlet-activity-tags>

  <pp-dashlet-activity-assignees
    *ngIf="change.propName === propNames.ASSIGNEES"
    [ppChange]="change"
    [ppAllUsers]="ppAllUsers"
  ></pp-dashlet-activity-assignees>
  <pp-dashlet-activity-custom-field
    *ngIf="change.propName === propNames.CUSTOM_FIELD && !change.richText"
    [ppChange]="change"
  ></pp-dashlet-activity-custom-field>

  <pp-dashlet-activity-custom-field-sub-value
    *ngIf="change.propName === propNames.CUSTOM_FIELD_SUB_VALUE && !change.richText"
    [ppChange]="change"
  ></pp-dashlet-activity-custom-field-sub-value>

  <pp-dashlet-activity-location
    *ngIf="
      change.propName === propNames.PIN ||
      change.propName === propNames.ADDITIONAL_PINS ||
      change.propName === propNames.PINS ||
      change.propName === propNames.POLYGONS
    "
  ></pp-dashlet-activity-location>

  <pp-dashlet-activity-flag
    *ngIf="change.propName === propNames.FLAGGED"
    [ppChange]="change"
  ></pp-dashlet-activity-flag>
</div>
