import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';

import { accountReducer } from './account.reducer';

import { LayoutModule } from '../layout/layout.module';
import { WhiteLabelModule } from '../white-label/white-label.module';

import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { AccountSubscriptionModule } from '../../components/account-subscription/account-subscription.module';
import { ArchivedChipModule } from '../../components/archived-chip/archived-chip.module';
import { BulkChangesBarModule } from '../../components/bulk-changes-bar/bulk-changes-bar.module';
import { ButtonModule } from '../../components/button/button.module';
import { DeleteButtonModule } from '../../components/delete-button/delete-button.module';
import { ErrorBoxModule } from '../../components/error-box/error-box.module';
import { IconModule } from '../../components/icon/icon-module';
import { CheckboxModule } from '../../components/input/checkbox/checkbox.module';
import { SelectModule } from '../../components/input/select/select.module';
import { LimitBarModule } from '../../components/limit-bar/limit-bar.module';
import { ModalModule } from '../../components/modal/modal.module';
import { SearchBoxModule } from '../../components/search-box/search-box.module';
import { SettingsHeaderModule } from '../../components/settings-header/settings-header.module';
import { SpinnerModule } from '../../components/spinner/spinner.module';
import { ToggleModule } from '../../components/toggle/toggle.module';
import { WarningBoxModule } from '../../components/warning-box/warning-box.module';
import { AutonumericModule } from '../../directives/autonumeric/autonumeric.module';
import { FocusModule } from '../../directives/focus/focus.module';
import { ImagesFallbackModule } from '../../directives/images-fallback/images-fallback.module';
import { OnRenderedModule } from '../../directives/on-rendered/on-rendered.module';
import { SecureSrcModule } from '../../directives/secure-src/secure-src.module';
import { SwitchCasesModule } from '../../directives/switch-cases/switch-cases.module';
import { TooltipsModule } from '../../features/tooltip/tooltips.module';
import { FiltersModule } from '../filters/filters.module';
import { PlaceholdersModule } from '../placeholders/placeholders.module';
import { RemindersModule } from '../reminders/reminders.module';
import { ShareModule } from '../share/share.module';
import { SiteModule } from '../site/site.module';
import { TagsModule } from '../tags/tags.module';
import { UserManagementModule } from '../user-management/user-management.module';
import { AccountService } from './account-service/account.service';
import { AccountSettingsDropdownComponent } from './account-settings-dropdown/account-settings-dropdown.component';
import { AccountGeneralSettingsComponent } from './account-settings/account-general-settings/account-general-settings.component';
import { AccountNameComponent } from './account-settings/account-name/account-name.component';
import { AccountOptionsFeaturesComponent } from './account-settings/account-settings-pages/account-settings-options/account-options-features/account-options-features.component';
import { AccountOptionsTogglesComponent } from './account-settings/account-settings-pages/account-settings-options/account-options-toggles/account-options-toggles.component';
import { AccountSettingsOptionsComponent } from './account-settings/account-settings-pages/account-settings-options/account-settings-options.component';
import { AccountSettingsSiteFooterComponent } from './account-settings/account-settings-pages/account-settings-sites/account-settings-site-footer/account-settings-site-footer.component';
import { AccountSettingsSiteComponent } from './account-settings/account-settings-pages/account-settings-sites/account-settings-site/account-settings-site.component';
import { AccountSettingsSitesBulkChangesComponent } from './account-settings/account-settings-pages/account-settings-sites/account-settings-sites-bulk-changes/account-settings-sites-bulk-changes.component';
import { AccountSettingsSitesComponent } from './account-settings/account-settings-pages/account-settings-sites/account-settings-sites.component';
import { AccountSitesHeaderButtonsComponent } from './account-settings/account-settings-pages/account-settings-sites/account-sites-header-buttons/account-sites-header-buttons.component';
import { AccountSettingUnlimitedInfoComponent } from './account-settings/account-settings-pages/account-settings-subscription/account-setting-unlimited-info/account-setting-unlimited-info.component';
import { AccountSettingsNumberPickerComponent } from './account-settings/account-settings-pages/account-settings-subscription/account-settings-number-picker/account-settings-number-picker.component';
import { AccountSettingsSubscriptionUpdateModalComponent } from './account-settings/account-settings-pages/account-settings-subscription/account-settings-subscription-update-modal/account-settings-subscription-update-modal.component';
import { AccountSettingsSubscriptionComponent } from './account-settings/account-settings-pages/account-settings-subscription/account-settings-subscription.component';
import { AccountSettingsTabsComponent } from './account-settings/account-settings-tabs/account-settings-tabs.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { AccountUserModalCustomFieldComponent } from './account-settings/account-user-modal/account-user-modal-custom-fields/account-user-modal-custom-field/account-user-modal-custom-field.component';
import { AccountUserModalCustomFieldsComponent } from './account-settings/account-user-modal/account-user-modal-custom-fields/account-user-modal-custom-fields.component';
import { AccountUserModalEditWarningComponent } from './account-settings/account-user-modal/account-user-modal-edit-warning/account-user-modal-edit-warning.component';
import { AccountUserModalSharesListBulkShareComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-bulk-share/account-user-modal-shares-list-bulk-share.component';
import { AccountUserModalSharesListShareAccessComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-access/account-user-modal-shares-list-share-access.component';
import { AccountUserModalSharesListShareAccessibleTagsComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-accessible-tags/account-user-modal-shares-list-share-accessible-tags.component';
import { AccountUserModalSharesListShareActivityComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-activity/account-user-modal-shares-list-share-activity.component';
import { AccountUserModalSharesListShareCommentsComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-comments/account-user-modal-shares-list-share-comments.component';
import { AccountUserModalSharesListShareCustomFieldsComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-custom-fields/account-user-modal-shares-list-share-custom-fields.component';
import { AccountUserModalSharesListShareExportsComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-exports/account-user-modal-shares-list-share-exports.component';
import { AccountUserModalSharesListShareSiteComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-site/account-user-modal-shares-list-share-site.component';
import { AccountUserModalSharesListShareTagsComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share-tags/account-user-modal-shares-list-share-tags.component';
import { AccountUserModalSharesListShareComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list-share/account-user-modal-shares-list-share.component';
import { AccountUserModalSharesListComponent } from './account-settings/account-user-modal/account-user-modal-shares-list/account-user-modal-shares-list.component';
import { AccountUserModalSiteListComponent } from './account-settings/account-user-modal/account-user-modal-site-list/account-user-modal-site-list.component';
import { EditAccountUserModalComponent } from './account-settings/account-user-modal/edit-account-user-modal.component';
import { AddUsersErrorBoxComponent } from './account-settings/add-users-modal/add-users-error-box/add-users-error-box.component';
import { AddUsersModalComponent } from './account-settings/add-users-modal/add-users-modal.component';
import { AddUsersModalWarningBoxComponent } from './account-settings/add-users-modal/add-users-warning-box/add-users-warning-box.component';
import { DowngradeAccountUserModalComponent } from './account-settings/downgrade-account-user-modal/downgrade-account-user-modal.component';
import { accountRoutes } from './account.routes';
import { FolderSettingsDropdownComponent } from './folder-settings-dropdown/folder-settings-dropdown.component';
import { NewAccountAdminModalConfirmationBlockComponent } from './new-account-admin-modal/new-account-admin-modal-confirmation-block/new-account-admin-modal-confirmation-block.component';
import { NewAccountAdminModalComponent } from './new-account-admin-modal/new-account-admin-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(accountRoutes),
    StoreModule.forFeature('accounts', accountReducer),
    ButtonModule,
    LayoutModule,
    DeleteButtonModule,
    RemindersModule,
    WhiteLabelModule,
    SiteModule,
    SelectModule,
    SpinnerModule,
    ToggleModule,
    CheckboxModule,
    FiltersModule,
    ScrollingModule,
    TooltipsModule,
    AutonumericModule,
    FocusModule,
    ImagesFallbackModule,
    OnRenderedModule,
    SwitchCasesModule,
    ShareModule,
    PipesModule,
    SearchBoxModule,
    ModalModule,
    SettingsHeaderModule,
    AccountSubscriptionModule,
    LimitBarModule,
    IconModule,
    PlaceholdersModule,
    SecureSrcModule,
    UserManagementModule,
    TagsModule,
    WarningBoxModule,
    ErrorBoxModule,
    BulkChangesBarModule,
    ArchivedChipModule,
  ],
  providers: [AccountService],
  declarations: [
    AccountSettingsComponent,
    EditAccountUserModalComponent,
    AccountGeneralSettingsComponent,
    AccountSettingsDropdownComponent,
    FolderSettingsDropdownComponent,
    AccountSettingsOptionsComponent,
    AccountOptionsFeaturesComponent,
    AccountOptionsTogglesComponent,
    AccountSettingsSitesComponent,
    AccountSettingsSiteComponent,
    AccountSettingsSubscriptionComponent,
    AccountNameComponent,
    AccountSettingsSubscriptionUpdateModalComponent,
    AccountSettingsNumberPickerComponent,
    AccountSettingUnlimitedInfoComponent,
    AccountUserModalSiteListComponent,
    AccountUserModalSharesListComponent,
    AccountUserModalSharesListShareComponent,
    AccountUserModalSharesListShareAccessComponent,
    AccountUserModalSharesListShareAccessibleTagsComponent,
    AccountUserModalSharesListShareActivityComponent,
    AccountUserModalSharesListShareExportsComponent,
    AccountUserModalSharesListShareCommentsComponent,
    AccountUserModalSharesListShareCustomFieldsComponent,
    AccountUserModalSharesListShareSiteComponent,
    AccountUserModalCustomFieldsComponent,
    AccountUserModalCustomFieldComponent,
    AddUsersModalComponent,
    AccountUserModalSharesListShareTagsComponent,
    AddUsersErrorBoxComponent,
    AccountUserModalEditWarningComponent,
    AddUsersModalWarningBoxComponent,
    NewAccountAdminModalComponent,
    NewAccountAdminModalConfirmationBlockComponent,
    AccountSettingsTabsComponent,
    DowngradeAccountUserModalComponent,
    AccountSitesHeaderButtonsComponent,
    AccountSettingsSiteFooterComponent,
    AccountSettingsSitesBulkChangesComponent,
    AccountUserModalSharesListBulkShareComponent,
  ],
})
export class AccountModule {}
