import { Component, EventEmitter, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-custom-field-subfield-delete-button',
  templateUrl: './custom-field-subfield-delete-button.component.html',
  styleUrl: './custom-field-subfield-delete-button.component.scss',
})
export class CustomFieldSubfieldDeleteButtonComponent {
  @Output() ppDeleteSubfield = new EventEmitter<void>();

  EIconPath = EIconPath;

  deleteSubfield(): void {
    this.ppDeleteSubfield.emit();
  }
}
