import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TDropdown } from 'src/app/project/components/dropdown/dropdown.consts';
import { TWorkspace } from 'src/app/project/modules/workspace/workspace.model';

import { UsersDropdownComponent } from 'src/app/project/modules/users/users-dropdown/users-dropdown.component';

import { TAllUsers } from '@project/view-models';
import { Subject, takeUntil } from 'rxjs';
import { blurInput } from 'src/app/core/helpers/blur-input';
import { DropdownService } from 'src/app/project/components/dropdown/dropdown-service/dropdown.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AddUsersIssuesService } from 'src/app/project/modules/account/account-settings/add-users-modal/add-users-issues.service';
import { TUsersDropdownData } from 'src/app/project/modules/users/users-dropdown/users-dropdown.model';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { ScreenService } from '../../../../../../core/services/window/screen.service';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { ShareEditEmailInputService } from './share-edit-email-input.service';

@Component({
  selector: 'pp-share-edit-email-input',
  templateUrl: './share-edit-email-input.component.html',
  styleUrls: ['./share-edit-email-input.component.scss'],
})
export class ShareEditEmailInputComponent implements OnInit, OnDestroy {
  @ViewChild('shareModalEmailInput', { static: false }) emailInputElement: ElementRef;

  @Input() ppEmailList: string[] = [];
  @Output() ppEmailListChange = new EventEmitter<string[]>();
  @Input() ppWorkspace: TWorkspace = null;
  @Input() ppDisabled: boolean;
  @Output() ppUpdate = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  dropdown: TDropdown = this.dropdownService.getDropdown();
  users: TAllUsers;
  dropdownVisible = false;
  EIconPath = EIconPath;
  suggestedUsersIds: string[] = [];
  focused = false;
  emailInput = '';
  emailInputId = 'shareModalEmailInput';
  usersWithErrors: string[] = [];
  emailList: string[] = [];

  constructor(
    private usersService: UsersService,
    private dropdownService: DropdownService,
    private screenService: ScreenService,
    private shareEditEmailInputService: ShareEditEmailInputService,
    private addUsersIssuesService: AddUsersIssuesService,
    private translationPipe: TranslationPipe,
  ) {
    this.subscribeToErrorChange();
    this.subscribeToWarningChange();
  }

  ngOnInit() {
    this.setUsers();
    this.setEmailList(this.ppEmailList);
    this.setEmailInput();
    this.setWarnings();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  blurInput(event: Event): void {
    blurInput(event.target);
  }

  onEmailChange(): void {
    // PS-2768 - Commented out the following code block, may reintroduce later
    // this.handleUsersDropdownState();
    this.setWarnings();
    this.tryUpdate();
  }

  onFocus(): void {
    if (this.ppDisabled) {
      return;
    }

    this.focused = true;
  }

  onBlur(): void {
    this.focused = false;
    const emailList = this.shareEditEmailInputService.splitKeywords(this.emailInput);

    this.setEmailList(emailList);
    this.ppEmailListChange.emit(emailList);
  }

  onInputRendered(element: HTMLElement): void {
    if (!this.ppDisabled) {
      element.focus();
    }
  }

  focusInput(): void {
    this.emailInputElement.nativeElement.focus();
  }

  removeEmailFromList(emailIndex: number): void {
    this.emailList.splice(emailIndex, 1);
    this.setEmailInput();
    this.setWarnings();
    this.ppEmailListChange.emit(this.emailList);
  }

  checkEmailError(email: string, index: number): boolean {
    return this.usersWithErrors.includes(email) || this.emailList.indexOf(email) !== index;
  }

  private setUsers(): void {
    this.users = this.usersService.getUsers();
  }

  private subscribeToErrorChange() {
    this.addUsersIssuesService.errorChange$.pipe(takeUntil(this.destroy$)).subscribe((errors) => {
      this.handleErrorsAndWarnings();
    });
  }

  private handleErrorsAndWarnings(): void {
    this.usersWithErrors = this.addUsersIssuesService.getUserEmailsWithErrors();
  }

  private subscribeToWarningChange() {
    this.addUsersIssuesService.warningChange$.pipe(takeUntil(this.destroy$)).subscribe((errors) => {
      this.handleErrorsAndWarnings();
    });
  }

  private setEmailList(emailList: string[]): void {
    this.emailList = [...emailList];
  }

  private setEmailInput(): void {
    this.emailInput = this.emailList.join(', ');
  }

  private handleUsersDropdownState(): void {
    const isMobileOrEmailInputEmpty =
      this.emailInput.length === 0 || this.screenService.getCurrentState().isMobile;

    if (isMobileOrEmailInputEmpty) {
      this.clearSuggestedUsersIds();
      this.hideUsersDropdown();
    } else {
      this.setSuggestedUsersIds();
      this.tryHideUsersDropdown();
      this.tryUpdateUsersDropdown();
      this.tryShowUsersDropdown();
    }
  }

  private clearSuggestedUsersIds(): void {
    this.suggestedUsersIds = [];
  }

  private hideUsersDropdown(): void {
    this.dropdownVisible = false;
    this.dropdownService.hideDropdown();
  }

  private setSuggestedUsersIds(): void {
    this.suggestedUsersIds = this.shareEditEmailInputService.getUsersThatMatchKeyword(
      this.ppWorkspace,
      this.emailInput,
    );
  }

  private tryHideUsersDropdown(): void {
    if (
      this.suggestedUsersIds.length === 0 ||
      (this.dropdown.visible && this.dropdown.buttonId !== this.emailInputId)
    ) {
      this.hideUsersDropdown();
    }
  }

  private tryUpdateUsersDropdown(): void {
    if (
      this.suggestedUsersIds.length > 0 &&
      this.dropdown.visible &&
      this.dropdown.buttonId === this.emailInputId
    ) {
      this.setUsersDropdownData();
    }
  }

  private setUsersDropdownData(): void {
    this.dropdownService.setData<TUsersDropdownData>({
      unselectedUsers: this.suggestedUsersIds,
      disabled: false,
      hideExtraOptions: true,
      type: 'users',
      showToggle: false,
      allUsers: this.suggestedUsersIds,
    });
  }

  private tryShowUsersDropdown(): void {
    if (this.suggestedUsersIds.length > 0 && !this.dropdown.visible) {
      this.setUsersDropdownData();

      this.dropdownService.showDropdown(this.emailInputId, UsersDropdownComponent, {
        callback: (id) => this.selectUser(id),
        suppressScrollbar: true,
        width: `${this.emailInputElement.nativeElement.clientWidth}px`,
      });
    }
  }

  private selectUser(userId: string): void {
    const email = this.users[userId].email;
    const keywordIndex = this.shareEditEmailInputService.getUserKeywordIndex(
      this.emailList,
      userId,
    );

    this.emailList[keywordIndex] = email;

    this.hideUsersDropdown();
    this.setEmailInput();
    this.tryUpdate();
    this.ppEmailListChange.emit(this.emailList);
  }

  private tryUpdate(): void {
    if (this.ppUpdate) {
      this.ppUpdate.emit();
    }
  }

  private setWarnings(): void {
    const emails = this.shareEditEmailInputService.splitKeywords(this.emailInput);
    const duplicates = emails.filter((item, index) => emails.indexOf(item) !== index);

    if (duplicates.length > 0) {
      this.addUsersIssuesService.setWarnings([
        this.translationPipe.transform('warning_duplicate_emails'),
      ]);
    } else {
      this.addUsersIssuesService.setWarnings([]);
    }
  }
}
