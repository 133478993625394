import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EUserRole } from '../../../../../share/share-utils/user-roles';
import { TShare } from '../../../../../share/share.model';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';
import { PrepareShareService } from './prepare-share.service';

@Component({
  selector: 'pp-account-user-modal-shares-list-share',
  templateUrl: './account-user-modal-shares-list-share.component.html',
  styleUrls: ['./account-user-modal-shares-list-share.component.scss'],
})
export class AccountUserModalSharesListShareComponent implements OnInit {
  @Input() ppWorkspaceId: string;
  @Input() ppHasError: boolean;
  @Output() ppShowCustomFieldsTable = new EventEmitter<void>();

  share: TShare;
  EUserRole = EUserRole;

  constructor(
    private prepareShareService: PrepareShareService,
    private editAccountUserModalDataService: EditAccountUserModalDataService,
  ) {
    this.editAccountUserModalDataService.sharesBulkChange$.subscribe(() => {
      this.share = this.editAccountUserModalDataService.getShare(this.ppWorkspaceId);
    });
  }

  ngOnInit(): void {
    this.share = this.prepareShareService.prepareShare(this.ppWorkspaceId);
    this.editAccountUserModalDataService.setShare(this.ppWorkspaceId, this.share);
  }

  showCustomFieldsTable(): void {
    this.ppShowCustomFieldsTable.emit();
  }

  updateShare(): void {
    this.editAccountUserModalDataService.setShare(this.ppWorkspaceId, this.share);
  }
}
