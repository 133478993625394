export const AUTH_COOKIE_NAME = 'wave-auth';

export const AUTH_COOKIE_REMEMBER_DAYS = 7;

export const AUTH_EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const AUTH_LOCAL_STORAGE_KEY = 'userAuth';
export const AUTH_TOKEN_HEADER_NAME = 'X-Serene-Wave-Auth-Token';
export const USER_EMAIL_HEADER_NAME = 'X-Serene-Wave-User-Email';

export enum EAuthRoute {
  LOGIN = 'login',
  RESET_PASSWORD = 'reset-password',
  PASSWORD_SETUP = 'passwords/setup/:id',
  PASSWORD_RESET = 'passwords/reset/:id',
  VERIFY = 'verify/:id',
  SIGNUP = 'signup',
  TWO_FACTOR_AUTHENTICATION_SETUP = '2fa-setup',
  TWO_FACTOR_AUTHENTICATION_LOGIN = '2fa-login',
}
