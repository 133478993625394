import { translate } from 'src/app/project/features/translate/translate';
import { EImportCustomFieldError } from './import-custom-fields.consts';

export function getImportCustomFieldError(error: EImportCustomFieldError): string {
  switch (error) {
    case EImportCustomFieldError.CFT_IMPORT_VOLY_INTEGRATION:
      return translate('cf_import_voly_integration_error');
    case EImportCustomFieldError.CFT_IMPORT_FORMULA_ERROR:
      return '';
    case EImportCustomFieldError.CFT_IMPORT_NAME_CONFLICT:
      return translate('cf_import_name_conflict_error');
    case EImportCustomFieldError.CFT_IMPORT_TIMELINE_LIMIT:
      return translate('cf_import_timeline_limit_error');
    default:
      return translate('prompt_error');
  }
}
