import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-custom-field-numeric-with-subfields-expand-collapse',
  templateUrl: './custom-field-numeric-with-subfields-expand-collapse.component.html',
  styleUrl: './custom-field-numeric-with-subfields-expand-collapse.component.scss',
})
export class CustomFieldNumericWithSubfieldsExpandCollapseComponent {
  @Input() ppCollapsed: boolean;
  @Input() ppNumberOfFields: number;
  @Input() ppDisabled: boolean;
  @Output() ppToggle = new EventEmitter<void>();

  EIconPath = EIconPath;

  toggleExpand(): void {
    if (!this.ppDisabled) {
      this.ppToggle.emit();
    }
  }
}
