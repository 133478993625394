<pp-modal>
  <ng-container heading> {{ 'delete_site' | ppTranslate }} </ng-container>

  <ng-container body>
    <span class="deleteSite__boldMessage">
      {{ 'delete_site_warning' | ppTranslate }} {{ siteName }}
    </span>

    <div class="deleteSite__consequencesContainer">
      <div class="no-margin deleteSite__underline">{{ 'delete_site_modal_1' | ppTranslate }}</div>

      <ul>
        <li class="deleteSite__consequences">
          <span class="no-margin">{{ 'delete_site_modal_2' | ppTranslate }}</span>
        </li>
        <li class="deleteSite__consequences">
          <span class="no-margin">{{ 'delete_site_modal_3' | ppTranslate }}</span>
        </li>
        <li class="deleteSite__consequences">
          <span class="no-margin">{{ 'delete_site_modal_4' | ppTranslate }}</span>
        </li>
      </ul>
    </div>
  </ng-container>

  <ng-container footer>
    <pp-modal-generic-footer
      [ppConfirmText]="'delete_site_confirm' | ppTranslate"
      [ppProcess]="processing"
      (ppCancel)="hideModal()"
      (ppConfirm)="deleteSite()"
      [ppError]="true"
    ></pp-modal-generic-footer>
  </ng-container>
</pp-modal>
