<section class="subfieldValue" [class.pointCF__input--focus]="focused">
  <div class="subfield__inner">
    <section
      ppTooltip
      [ppTitle]="ppUnitTooltip"
      class="customFieldNumeric__unitWrapper"
      [class.customFieldNumeric__unitWrapper--hidden]="!ppUnit"
    >
      <span class="customFieldNumeric__unit" *ngIf="ppUnit">
        {{ ppUnit }}
      </span>

      <pp-calculator-icon *ngIf="ppCanEdit"></pp-calculator-icon>
    </section>

    <input
      *ngIf="ppCanEdit"
      [ppAutonumeric]="autonumericOptions"
      #subfieldValue
      class="subfield__value"
      [class.subfield__value--noUnit]="!ppUnit"
      [(ngModel)]="ppValue"
      (ngModelChange)="onValueChange($event)"
      [placeholder]="placeholder"
      (ppFormatted)="updateField($event)"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="onKeyDown($event)"
      (keydown.enter)="blurInput($event)"
    />

    <input
      *ngIf="!ppCanEdit"
      [ppAutonumeric]="autonumericOptions"
      [class.subfield__value--noUnit]="!ppUnit"
      #subfieldValue
      class="subfield__value"
      [(ngModel)]="ppValue"
      [placeholder]="placeholder"
      disabled
      readonly
    />
  </div>
</section>
