import {
  detailedList,
  individualReports,
  pdf,
  simpleList,
  sitePlan,
  TExportType,
  word,
  wordSimple,
  xlsxOutput,
} from '../point-export-modal/export-types';
import { TExportModalData } from './export-modal-data';

export function generateExportTypes(
  data: TExportModalData,
  sitePlanImageId: string,
): TExportType[] {
  const exportTypes: TExportType[] = [];

  if (data && data.formats) {
    if (data.formats.simpleList) {
      exportTypes.push(simpleList);
    }

    if (data.formats.detailedList) {
      exportTypes.push(detailedList);
    }

    if (data.formats.xlsxOutput) {
      exportTypes.push(xlsxOutput);
    }

    if (data.formats.individualReports && data.workspaceId) {
      exportTypes.push(individualReports);
    }

    if (data.formats.pdf && data.workspaceId) {
      exportTypes.push(pdf);
    }

    if ((data.formats.word || data.formats.wordPoints) && data.workspaceId) {
      exportTypes.push(word);
    }

    if ((data.formats.word || data.formats.wordPoints) && !data.workspaceId) {
      exportTypes.push(wordSimple);
    }

    if (data.formats.sitePlan && sitePlanImageId) {
      exportTypes.push(sitePlan);
    }
  }

  return exportTypes;
}
