import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { logErrorInSentry } from '../../../errors/response-error';
import { DefaultShareService } from '../../../share/default-share.service';
import { TShare } from '../../../share/share.model';
import { TSharesByWorkspace } from './edit-account-user-modal.consts';

@Injectable({
  providedIn: 'root',
})
export class EditAccountUserModalDataService {
  private readonly _sharesBulkChange = new Subject<void>();
  readonly sharesBulkChange$ = this._sharesBulkChange.asObservable();

  private selectedWorkspaces: string[] = [];
  private emailList: string[] = [];
  private sharesMap: TSharesByWorkspace = {};

  private readonly _emailListChange$ = new Subject<string[]>();
  readonly emailListChange$ = this._emailListChange$.asObservable();

  constructor(private defaultShareService: DefaultShareService) {}

  updateWorkspacesData(selectedWorkspaces: string[]): void {
    this.setSelectedWorkspaces(selectedWorkspaces);
    this.filterSharesMap();
  }

  getSelectedWorkspaces(): string[] {
    return this.selectedWorkspaces;
  }

  setEmailList(emailList: string[]): void {
    if (!isEqual(this.emailList, emailList)) {
      this.emailList = [...emailList];

      this._emailListChange$.next(emailList);
    }
  }

  getEmailList(): string[] {
    return this.emailList;
  }

  getSharesMap(): TSharesByWorkspace {
    return this.sharesMap;
  }

  setSharesMap(sharesMap: TSharesByWorkspace): void {
    this.sharesMap = sharesMap;

    this._sharesBulkChange.next();
  }

  getShare(workspaceId: string): TShare {
    return this.sharesMap[workspaceId];
  }

  setShare(workspaceId: string, share: TShare): void {
    if (workspaceId === null) {
      const error = new Error('workspaceId cannot be null');
      logErrorInSentry(error);
      throw error;
    }

    this.sharesMap[workspaceId] = share;

    this._sharesBulkChange.next();
  }

  clearData(): void {
    this.updateWorkspacesData([]);
    this.setEmailList([]);
    this.setSharesMap({});
  }

  filterSharesMap(): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      if (!this.selectedWorkspaces.includes(workspaceId)) {
        delete this.sharesMap[workspaceId];
      }
    });
  }

  bulkChangeRole(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      const oldShare = this.sharesMap[workspaceId];
      const shareOption = oldShare.shareOption;

      if (shareOption === share.shareOption) {
        return;
      }

      const newShare = { ...oldShare, shareOption: share.shareOption };
      this.sharesMap[workspaceId] = this.defaultShareService.resetShareToDefaults(newShare);
    });

    this._sharesBulkChange.next();
  }

  bulkChangeTags(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      this.sharesMap[workspaceId].advancedAccessLevels.tags.permission = {
        read: share.advancedAccessLevels.tags.permission.read,
        edit: share.advancedAccessLevels.tags.permission.edit,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeActivity(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      this.sharesMap[workspaceId].advancedAccessLevels.timeline.permission = {
        read: share.advancedAccessLevels.timeline.permission.read,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeExports(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      this.sharesMap[workspaceId].advancedAccessLevels.exports.permission = {
        read: share.advancedAccessLevels.exports.permission.read,
      };
    });

    this._sharesBulkChange.next();
  }

  bulkChangeComments(share: TShare): void {
    Object.keys(this.sharesMap).forEach((workspaceId) => {
      this.sharesMap[workspaceId].advancedAccessLevels.timeline.comments.permission = {
        read: share.advancedAccessLevels.timeline.comments.permission.read,
        edit: share.advancedAccessLevels.timeline.comments.permission.edit,
      };
    });

    this._sharesBulkChange.next();
  }

  private setSelectedWorkspaces(selectedWorkspaces: string[]): void {
    this.selectedWorkspaces = selectedWorkspaces;
  }
}
