import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import AutoNumeric from 'autonumeric';
import { createAutonumeric, TAutoNumericOptions } from '../../../core/helpers/create-autonumeric';

@Directive({
  selector: '[ppAutonumeric]',
})
export class AutonumericDirective implements OnChanges {
  @Input() public ppAutonumeric: TAutoNumericOptions;
  @Output() ppFormatted = new EventEmitter<string>();

  private element: HTMLInputElement;
  private autonumeric: AutoNumeric;

  constructor(private elementRef: ElementRef) {
    this.element = this.elementRef.nativeElement;
  }

  ngOnChanges() {
    if (!this.autonumeric) {
      this.autonumeric = createAutonumeric(this.element, this.ppAutonumeric);

      this.element.addEventListener('blur', (event) => {
        const newValue = this.autonumeric.getNumber();

        this.ppFormatted.emit(newValue.toString());
      });
    } else {
      this.autonumeric.update(this.ppAutonumeric);
    }
  }
}
