export function formatCurrency(value: number, symbol: string, decimalPlaces?: number): string {
  if (!value) return '';

  const decimalsCorrect = !isNaN(decimalPlaces) && typeof decimalPlaces === 'number';
  const numberOfDecimalPlaces: number = decimalsCorrect ? decimalPlaces : 2;

  const formattedValue = value.toFixed(numberOfDecimalPlaces);

  if (!symbol) return addCommas(formattedValue);

  return `${symbol} ${addCommas(formattedValue)}`;
}

function addCommas(num: string): string {
  const str: string[] = num.toString().split('.');

  if (str[0].length >= 3) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }

  return str.join('.');
}
