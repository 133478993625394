import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';

@Component({
  selector: 'pp-custom-field-subfield-save-button',
  templateUrl: './custom-field-subfield-save-button.component.html',
  styleUrl: './custom-field-subfield-save-button.component.scss',
})
export class CustomFieldSubfieldSaveButtonComponent {
  @Input() ppDisabled: boolean;
  @Output() ppSaveSubfield = new EventEmitter<void>();

  EIconPath = EIconPath;

  saveSubfield(): void {
    this.ppSaveSubfield.emit();
  }
}
