import { Injectable } from '@angular/core';
import { EUserRole } from '../../share/share-utils/user-roles';
import { WorkspaceService } from '../workspace.service';

@Injectable({
  providedIn: 'root',
})
export class CheckWorkspacePermissionsService {
  constructor(private workspaceService: WorkspaceService) {}

  isWorkspaceAdmin(workspaceId: string): boolean {
    const workspace = this.workspaceService.findWorkspace(workspaceId);
    const share = workspace.share;

    const isAdminUser =
      share.shareOption === EUserRole.SITE_ADMIN ||
      share.shareOption === EUserRole.OWNER ||
      share.shareOption === EUserRole.ACCOUNT_ADMIN;

    return isAdminUser;
  }
}
