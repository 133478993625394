<div class="pointCF__field">
  <label class="pointCF__label">
    <div class="pointCF__label-image--container">
      <pp-icon [ppSrc]="EIconPath.ICON_FIELDS_FORMULA_18" ppColor="inherit" ppClass="setSize18" />
    </div>

    <span
      ppTooltip
      [ppTitle]="ppFieldLabel"
      [ppTrimTooltip]="true"
      class="pointCF__label-container"
    >
      {{ ppFieldLabel }}
    </span>
  </label>

  <div class="pointCF__formulaWrapper">
    <div class="pointCF__formulaContent">
      <span
        class="customFieldNumeric__unitWrapper"
        *ngIf="unitSymbol"
        ppTooltip
        [ppTitle]="unitSymbolTooltip"
      >
        <span class="customFieldNumeric__unit">
          {{ unitSymbol }}
        </span>
      </span>

      <div
        ppTooltip
        [ppSanitize]="false"
        [ppTitle]="tooltipTitle"
        class="pointCF__formula"
        [class.pointCF__formula--borderLeft]="!!unitSymbol"
      >
        {{ value }}
      </div>
    </div>
  </div>
</div>
