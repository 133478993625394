import { Component, DoCheck, EventEmitter, Input, Output } from '@angular/core';
import { TSelectOption } from 'src/app/project/components/input/select-tag/select-tag.model';
import { getDisabledUserManagementTooltipText } from 'src/app/project/modules/user-management/user-management-table-cells/utils/get-disabled-user-management-cell-tooltip-text';
import { translate } from '../../../../../../../features/translate/translate';
import { ESimplePermission } from '../../../../../../share/permissions.consts';
import {
  convertGranularPermissionToSimplePermission,
  convertSimplePermissionToGranularPermission,
} from '../../../../../../share/share-utils/permissions-converter.utils';
import { TShare } from '../../../../../../share/share.model';

@Component({
  selector: 'pp-account-user-modal-shares-list-share-comments',
  templateUrl: './account-user-modal-shares-list-share-comments.component.html',
  styleUrls: ['./account-user-modal-shares-list-share-comments.component.scss'],
})
export class AccountUserModalSharesListShareCommentsComponent implements DoCheck {
  @Input() ppShare: TShare;
  @Output() ppShareChange = new EventEmitter<TShare>();
  @Input() ppDisabled: boolean;

  index: number = null;
  disabledTooltipText: string;
  shareOptions: TSelectOption[] = [
    { label: translate('can_edit'), value: ESimplePermission.EDIT },
    { label: translate('view_only'), value: ESimplePermission.VIEW },
    { label: translate('hide'), value: ESimplePermission.NONE },
  ];

  ngDoCheck(): void {
    this.setItemIndex();
    this.disabledTooltipText = getDisabledUserManagementTooltipText(this.ppShare.shareOption);
  }

  private setItemIndex(): void {
    const simplePermission = convertGranularPermissionToSimplePermission(
      this.ppShare.advancedAccessLevels.timeline.comments.permission,
    );
    this.index = this.shareOptions.findIndex((option) => option.value === simplePermission);
  }

  updatePermissions(item: TSelectOption): void {
    const newPermissions = convertSimplePermissionToGranularPermission(
      item.value as ESimplePermission,
    );

    const newShare: TShare = { ...this.ppShare };

    newShare.advancedAccessLevels.timeline.comments.permission = newPermissions;

    this.ppShareChange.emit(newShare);
  }
}
