import { Injectable } from '@angular/core';
import { DashboardApiProviderService } from '@core/api';
import { isEqual } from 'lodash';
import { catchError, Observable, of, tap } from 'rxjs';
import { ResponseErrorService } from '../errors/response-error.service';
import {
  TDashletCostComparisonResponse,
  TDashletLiveResponse,
  TDashletReportResponse,
  TDashletRequestData,
  TDashletStackedResponse,
} from './dashboard.consts';
import { TDashletReportRequestData } from './dashlet-report.service.consts';
import { EDashletType } from './dashlets-enums';

export type TDashletEventsParams = {
  type?: string;
  period?: string;
  accountId?: string;
  workspaceIds?: string[];
};

export type TDashletData = TDashletRequestData & {
  data: number;
};

@Injectable({
  providedIn: 'root',
})
export class DashboardDataFetchingService {
  private requestsQueue: TDashletReportRequestData[] = [];

  constructor(
    private responseErrorService: ResponseErrorService,
    private dashboardApiProviderService: DashboardApiProviderService,
  ) {}

  fetchCurrentTypeCount(body: TDashletRequestData): Observable<TDashletLiveResponse> {
    return this.handleRequest(
      this.findExistingRequest(body.type, body.period, body.workspaceIds, body.accountId),
      () => this.dashboardApiProviderService.fetchCurrentTypeCount(body),
      body,
    );
  }

  fetchReports(body: TDashletRequestData): Observable<TDashletReportResponse> {
    return this.handleRequest(
      this.findExistingRequest(body.type, body.period, body.workspaceIds, body.accountId),
      () => this.dashboardApiProviderService.fetchReports(body),
      body,
    );
  }

  fetchCostComparison({
    assetId = '',
    workspacesCosts = {},
  }: {
    assetId: string;
    workspacesCosts: { [workspaceId: string]: string[] };
  }): Observable<TDashletCostComparisonResponse> {
    return this.handleRequest(
      this.findExistingRequest(
        EDashletType.COST_COMPARISON,
        null,
        null,
        null,
        assetId,
        workspacesCosts,
      ),
      () =>
        this.dashboardApiProviderService.fetchCostComparison({
          assetId,
          workspacesCosts,
        }),
      {
        type: EDashletType.COST_COMPARISON,
        period: null,
        accountId: null,
        workspaceIds: null,
        assetId,
        workspacesCosts,
      },
    );
  }

  fetchStackedStatus({ accountId = '', workspaceIds = [] }) {
    if (!workspaceIds.length) return of({});

    return this.handleRequest(
      this.findExistingRequest(EDashletType.CURRENT_STACKED_STATUS, null, workspaceIds, accountId),
      () =>
        this.dashboardApiProviderService.fetchStackedStatus({
          ...(accountId ? { accountId } : {}),
          workspaceIds,
          type: EDashletType.CURRENT_STACKED_STATUS,
        }),
      {
        type: EDashletType.CURRENT_STACKED_STATUS,
        period: null,
        accountId,
        workspaceIds,
        assetId: null,
        workspacesCosts: null,
      },
    );
  }

  clearDashletData(): void {
    this.requestsQueue = [];
  }

  getRequestQueue(): TDashletReportRequestData[] {
    return this.requestsQueue;
  }

  private handleRequest<
    T extends
      | TDashletStackedResponse
      | TDashletReportResponse
      | TDashletCostComparisonResponse
      | TDashletLiveResponse,
  >(
    existingRequest: Partial<TDashletReportRequestData>,
    apiCall: () => Observable<T>,
    requestData: Partial<TDashletReportRequestData>,
  ): Observable<T> {
    if (existingRequest) {
      return existingRequest.request as Observable<T>;
    }

    const request = apiCall();
    this.requestsQueue.push({ ...requestData, request } as TDashletReportRequestData);

    return request.pipe(
      tap((response) => {
        const foundRequest = this.findExistingRequest(
          requestData.type,
          requestData.period,
          requestData.workspaceIds,
          requestData.accountId,
          requestData.assetId,
          requestData.workspacesCosts,
        );
        foundRequest.data = response;
      }),
      catchError(this.responseErrorService.handleRequestError),
    );
  }

  private findExistingRequest(
    type: string,
    period: string,
    workspaceIds: string[],
    accountId: string,
    assetId?: string,
    workspacesCosts?: { [workspaceId: string]: string[] },
  ): TDashletReportRequestData {
    return this.requestsQueue.find((_dashlet) => {
      const typeMatch = _dashlet.type === type;
      const periodMatch = _dashlet.period === period;
      const workspaceIdsMatch =
        (!_dashlet.workspaceIds && !workspaceIds) ||
        (_dashlet.workspaceIds?.length === workspaceIds?.length &&
          _dashlet.workspaceIds.every((workspaceId) => workspaceIds.includes(workspaceId)));
      const accountIdMatch =
        _dashlet.accountId === accountId || (!_dashlet.accountId && !accountId);
      const assetIdMatch = _dashlet.assetId === assetId || (!_dashlet.assetId && !assetId);
      const workspaceCostsMatch =
        isEqual(_dashlet.workspacesCosts, workspacesCosts) ||
        (!_dashlet.workspacesCosts && !workspacesCosts);

      return (
        typeMatch &&
        periodMatch &&
        workspaceIdsMatch &&
        accountIdMatch &&
        assetIdMatch &&
        workspaceCostsMatch
      );
    });
  }
}
