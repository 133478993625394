import {
  Component,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';

import { TPoint } from 'src/app/project/modules/points/points.model';

import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { DOCUMENT } from '@angular/common';
import { TPointUpdate } from '@project/view-models';
import { of, Subject, timer } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { getFullUnitName } from 'src/app/core/helpers/get-unit-name';
import { trimNumericInput } from 'src/app/core/helpers/trim-numeric-input';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TAutoNumericOptions } from '../../../../../../core/helpers/create-autonumeric';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { PointsService } from '../../../points.service';

@Component({
  selector: 'pp-point-fields-numbers',
  templateUrl: './point-fields-numbers.component.html',
  styleUrls: ['./point-fields-numbers.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsNumbersComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppField: TPointCustomField;
  @Input() ppSubfieldsActive: boolean;

  private readonly destroy$ = new Subject<void>();

  error = false;
  unit = '';
  value: string;
  decimalPlaces = 2;
  showCommas: boolean;
  EIconPath = EIconPath;
  EIntegrationStatus = EIntegrationStatus;
  unitName: string;

  autonumericOptions: TAutoNumericOptions;

  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private customFieldsService: CustomFieldsService,
    private translationPipe: TranslationPipe,
    private ngZone: NgZone,
    private pointsService: PointsService,
  ) {}

  ngOnInit() {
    this.setAutonumericOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppPointId) {
      const point: TPoint = this.pointsService.findPoint(this.ppFieldId);
      const customField =
        this.customFieldsService.getCustomFields()[this.ppWorkspaceId]?.[this.ppFieldId];

      if (customField) {
        this.unit = customField.unit;
        this.unitName = getFullUnitName(this.unit);

        this.showCommas = customField.showCommas;
        this.setAutonumericOptions();
      }

      if (point && this.ppField) {
        this.ppField.value = point.customFieldsMap[this.ppFieldId]?.value;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateField(value: string): void {
    this.value = value;
    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = this.value;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        take(1),
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    const body: TPointUpdate = {
      customFieldsList: [
        {
          customFieldTemplateId: this.ppFieldId,
          value: trimNumericInput(fieldValue),
        },
      ],
    };

    this.pointsUpdateService
      .updatePointField(_id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_numbers_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
      )
      .subscribe();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue ? trimNumericInput(fieldValue) : fieldValue,
      }),
    );
  }

  private setAutonumericOptions(): void {
    this.autonumericOptions = {
      modifyValueOnWheel: false,
      decimalPlaces: this.decimalPlaces,
      decimalPlacesRawValue: this.decimalPlaces,
      digitGroupSeparator: this.showCommas ? ',' : '',
      formulaMode: true,
      watchExternalChanges: true,
    };
  }
}
