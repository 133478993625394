<div class="expandCollapse" (click)="toggleExpand()">
  <p class="expandCollapse__text" [ngClass]="ppDisabled ? 'expandCollapse__disabled' : ''">
    <span class="expandCollapse__text--underline">
      {{ (ppCollapsed ? 'subfield_expand' : 'subfield_collapse') | ppTranslate }}</span
    >
    <span> ({{ ppNumberOfFields }})</span>
  </p>

  <pp-icon
    ppClass="setSize18"
    ppColor="grey-400"
    [ppSrc]="ppCollapsed ? EIconPath.ICON_MISC_RIGHT_ARROW_18 : EIconPath.ICON_MISC_DOWN_ARROW_18"
  ></pp-icon>
</div>
