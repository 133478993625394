<button
  class="subfieldSaveButton"
  [class.subfieldSaveButton--disabled]="ppDisabled"
  (click)="saveSubfield()"
>
  <pp-icon
    [ppSrc]="EIconPath.ICON_NOTIFICATION_CHECK_18"
    ppClass="setSize18"
    ppColor="white"
  ></pp-icon>
</button>
