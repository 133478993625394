import { Component, Input, OnChanges } from '@angular/core';
import { EAuthorizationProgressStep } from 'src/app/project/components/authorization-progress/authorization-progress.consts';
import {
  EIntegrationStatus,
  TWorkspaceCustomFields,
} from 'src/app/project/modules/custom-fields/custom-fields.model';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import {
  COST_CENTRE_CF_NAME,
  EXPENDITURE_CF_NAME,
  NET_CF_NAME,
  TOTAL_CF_NAME,
  VAT_CF_NAME,
} from './site-settings-integrations-custom-fields.consts';
import { sortVolyCustomFields } from './utils/sort-voly-custom-fields';

type TIntegrationField = {
  fieldName: string;
  active: boolean;
  customFieldId: string;
  subList?: any[];
};

@Component({
  selector: 'pp-site-settings-integrations-custom-fields',
  templateUrl: './site-settings-integrations-custom-fields.component.html',
  styleUrl: './site-settings-integrations-custom-fields.component.scss',
})
export class SiteSettingsIntegrationsCustomFieldsComponent implements OnChanges {
  @Input() ppIntegrationStep: EAuthorizationProgressStep;
  @Input() ppDisabled: boolean;
  @Input() ppWorkspaceId: string;

  EIconPath = EIconPath;
  integrationFields: TIntegrationField[] = [];
  disabled: boolean;

  constructor(private customFieldsService: CustomFieldsService) {}

  ngOnChanges(): void {
    const customFields = this.customFieldsService.getWorkspaceCustomFields(this.ppWorkspaceId);

    if (customFields && this.ppIntegrationStep === EAuthorizationProgressStep.ACTIVE) {
      this.setIntegrationFields(customFields);
    } else {
      this.setDefaultIntegrationFields();
    }

    this.disabled = this.ppIntegrationStep !== EAuthorizationProgressStep.ACTIVE || this.ppDisabled;
  }

  private setIntegrationFields(customFields: TWorkspaceCustomFields): void {
    let integrationFields = Object.values(customFields).filter(
      (field) => field.volyIntegrationActive === EIntegrationStatus.ON,
    );

    integrationFields = sortVolyCustomFields(integrationFields);

    this.integrationFields = integrationFields.map((field) => {
      const newField: TIntegrationField = {
        fieldName: field.label + (field.currencyCode ? ` (${field.currencyCode})` : ''),
        active: field.display,
        customFieldId: field.id,
      };

      if (field.subList) newField.subList = field.subList;

      return newField;
    });
  }

  private setDefaultIntegrationFields(): void {
    this.integrationFields = [
      {
        fieldName: NET_CF_NAME,
        active: false,
        customFieldId: null,
      },
      {
        fieldName: VAT_CF_NAME,
        active: false,
        customFieldId: null,
      },
      {
        fieldName: TOTAL_CF_NAME,
        active: false,
        customFieldId: null,
      },
      {
        fieldName: EXPENDITURE_CF_NAME,
        active: false,
        customFieldId: null,
        subList: [],
      },
      {
        fieldName: COST_CENTRE_CF_NAME,
        active: false,
        customFieldId: null,
        subList: [],
      },
    ];
  }
}
