<div class="subfield" #subfieldElement>
  <pp-custom-field-subfield-number-input
    [ppValue]="'' + ppSubfield.value"
    (ppValueChange)="markInputAsEdited($event)"
    [ppCanEdit]="ppCanEdit"
    [ppUnit]="ppUnit"
    [ppUnitTooltip]="ppUnitTooltip"
    (ppInputChange)="onInputChange($event)"
    [(ppFocused)]="focused"
    [ppDecimalPlaces]="ppDecimalPlaces"
  ></pp-custom-field-subfield-number-input>

  <pp-custom-field-subfield-description-input
    [(ppValue)]="ppSubfield.description"
    (ppValueChange)="markFieldAsEdited()"
    [ppCanEdit]="ppCanEdit"
    [(ppFocused)]="focused"
    (ppDescriptionChange)="onDescriptionChange()"
  ></pp-custom-field-subfield-description-input>

  <pp-custom-field-subfield-delete-button
    *ngIf="ppCanEdit && saveButtonState === ECustomFieldSubfieldDeleteButtonState.DELETE"
    (ppDeleteSubfield)="deleteSubfield()"
  ></pp-custom-field-subfield-delete-button>

  <pp-custom-field-subfield-save-button
    *ngIf="
      (ppCanEdit && saveButtonState === ECustomFieldSubfieldDeleteButtonState.CONFIRM) ||
      saveButtonState === ECustomFieldSubfieldDeleteButtonState.CONFIRM_DISABLED
    "
    [ppDisabled]="saveButtonState === ECustomFieldSubfieldDeleteButtonState.CONFIRM_DISABLED"
    (ppSaveSubfield)="saveSubfield()"
  ></pp-custom-field-subfield-save-button>
</div>
