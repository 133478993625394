import { Injectable } from '@angular/core';
import { NEW_POINT_ID } from 'src/app/project/shared/constants/point.const';
import { CombineCustomFieldsService } from '../../../custom-fields/combine-custom-fields/combine-custom-fields.service';
import { compareCustomFieldColumns } from '../../../custom-fields/combine-custom-fields/compare-custom-fields';
import { TCustomField } from '../../../custom-fields/custom-fields.model';
import { TColumn } from '../columns/column.model';
import { generateDefaultColumns, getDefaultWidth } from '../columns/columns';
import { EDefaultColumnWidths } from '../columns/default-column-widths-enum';
import { TableColumnsService } from '../columns/table-columns.service';
import { findPropertyPath } from '../properties';
import { GET_TABLE } from '../table.ui.store';
import Table from './table/Table';
import { checkColumnsToAdd } from './table/utils/check-columns-to-add';
import { checkColumnsToDelete } from './table/utils/check-columns-to-delete';
import { updatePointCustomFields } from './table/utils/update-point-custom-field';

@Injectable({
  providedIn: 'root',
})
export class CustomTableService {
  constructor(
    private tableColumnsService: TableColumnsService,
    private combineCustomFieldsService: CombineCustomFieldsService,
  ) {}

  getTable(): Table {
    return GET_TABLE();
  }

  checkColumns(customFields: TCustomField[], updatePreferences: boolean = true): void {
    const oldColumns = this.tableColumnsService.getColumns();
    const table = GET_TABLE();

    if (customFields) {
      const { columns, columnsChanged } = this.checkColumnsChanged(oldColumns, customFields);

      this.tableColumnsService.setColumns(columns);

      table.tableHead.updateWidth();
      table.tableBody.updateWidth();
      table.tableFooter.updateWidth();
      table.tableBody.updateColumns();

      if (columnsChanged && !table.defaultColumnsLoaded && updatePreferences) {
        table.savePreferencesCallback();
      }
    }
  }

  generateDefaultSiteColumns(customFields: TCustomField[]): TColumn[] {
    const columnsToCheck = this.resetColumnData();
    const oldColumns = columnsToCheck;

    if (customFields) {
      const { columns } = this.checkColumnsChanged(oldColumns, customFields);

      return columns;
    }

    return oldColumns;
  }

  updatePoint({
    _id,
    field,
    newValue = null,
    updatedDate = null,
  }: {
    _id: string;
    field: string;
    newValue?: unknown;
    updatedDate?: number;
  }): void {
    const columns = this.tableColumnsService.getColumns();
    const table = GET_TABLE();

    if (table) {
      const pointIndex = table.points.findIndex((point) => point._id === _id);

      if (pointIndex > -1 && _id !== NEW_POINT_ID) {
        this.updatePointField(pointIndex, field, newValue);

        if (updatedDate) {
          this.updatePointUpdatedDate(pointIndex, updatedDate);
        }
      }

      if (pointIndex > -1) {
        const columnIndex = columns.findIndex((column) => column.name === field);

        table.tableBody.updatePoint(pointIndex, columnIndex);

        if (updatedDate) {
          const updatedColumnIndex = columns.findIndex((column) => column.name === 'Updated');

          table.tableBody.updatePoint(pointIndex, updatedColumnIndex);
        }
      }
    }
  }

  private updatePointField(pointIndex: number, field: string, newValue: unknown): void {
    const table = GET_TABLE();

    let propertyName = findPropertyPath(field);
    let isCustomField = false;

    if (!propertyName) {
      propertyName = field;
      isCustomField = true;
    }

    if (isCustomField) {
      updatePointCustomFields(pointIndex, field, newValue, table.points);
    }
  }

  private updatePointUpdatedDate(pointIndex: number, newValue: number): void {
    const table = GET_TABLE();

    table.points[pointIndex].header.updatedEpochMillis = newValue;

    table.sortTable();
  }

  checkColumnsChanged(
    columns: TColumn[],
    customFields: TCustomField[],
  ): { columns: TColumn[]; columnsChanged: number } {
    const table = this.getTable();
    const { columnsToHide, columnsToShow } = table.checkColumnVisibility();
    const columnsToAdd = checkColumnsToAdd(customFields);
    const columnsToDelete = checkColumnsToDelete(customFields, table.overview);

    columnsToAdd.forEach((customField) => {
      const newColumn = {
        name: customField.name,
        index: columns.length,
        width: EDefaultColumnWidths.CUSTOM_FIELD,
        showTotal: customField.showTotal || typeof customField.showTotal === 'undefined',
        customFieldIds: customField.customFieldIds,
        unit: customField.unit,
        decimalPlaces: customField.decimalPlaces,
        currency: customField.currency,
        subValuesActive: !!customField.subValuesActive,
      };

      const existingColumn = columns.find((column) => {
        return compareCustomFieldColumns(newColumn, column);
      });

      if (!existingColumn) {
        table.visibleColumns.push(newColumn);
        columns.push(newColumn);
        table.width += newColumn.width;
      } else {
        existingColumn.customFieldIds.push(customField.customFieldIds[0]);
        table.defaultColumnsLoaded = false;
      }
    });

    columnsToDelete.forEach((column) => {
      const columnIndex = columns.findIndex((columnToDelete) => columnToDelete.name === column);

      if (columnIndex !== -1) {
        table.width -= columns[columnIndex].width;

        table.tableHead.removeColumn(columnIndex);
        columns.splice(columnIndex, 1);
      }
    });

    columns.forEach((_column, _index) => {
      _column.index = _index;
    });

    return {
      columns,
      columnsChanged:
        columnsToAdd.length ||
        columnsToDelete.length ||
        columnsToHide.length ||
        columnsToShow.length,
    };
  }

  resetColumnData(): TColumn[] {
    const columns = generateDefaultColumns();

    columns.forEach((column) => {
      column.width = getDefaultWidth(column.name);
      delete column.groupIndex;
      delete column.groupOrder;
      delete column.sortIndex;
      delete column.sortOrder;
      delete column.customFieldIds;
      column.hidden = false;
    });

    return columns;
  }
}
