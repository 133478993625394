import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';

import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TAttachments } from 'src/app/project/modules/points/attachments/attachments.model';

import { GalleryOverlayService } from 'src/app/project/components/gallery-overlay/gallery-overlay.service';
import { CustomFieldsService } from 'src/app/project/modules/custom-fields/custom-fields.service';
import { PointsHelperService } from 'src/app/project/modules/points/points-helper.service';
import { UsersService } from 'src/app/project/modules/users/users.service';
import { RequestService } from 'src/app/project/services/requests/request.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';

import { TActivityResponseChange, TAllUsers } from '@project/view-models';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { EActivityPropName } from '../../../../../shared/enums/activity-prop-name.enum';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { TFileChangeInfo } from '../../../../notifications/notification.model';
import { PointActivityMergeService } from '../point-activity-merge.service';

@Component({
  selector: 'pp-activity-change',
  templateUrl: './activity-change.component.html',
  styleUrls: ['./activity-change.component.scss'],
})
export class ActivityChangeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() ppChange;
  @Input() ppAuthor: string;
  @Input() ppCustomFieldId: string;
  @Input() ppWorkspaceId: string;
  @Input() ppVersion = 0;

  stringChange: TActivityResponseChange<string>;
  stringsChange: TActivityResponseChange<string[]>;
  type: ECustomFieldType | EIntegrationStatus = null;
  ECustomFieldType = ECustomFieldType;
  EIntegrationStatus = EIntegrationStatus;

  private readonly destroy$ = new Subject<void>();
  EActivityPropName = EActivityPropName;
  EIconPath = EIconPath;

  media: TAttachments['media'] = {
    dates: [],
    attachments: {},
  };

  showMore = false; // delete if showing only descriptionRich
  allUsers: TAllUsers = {};

  private attachments$: Observable<TAttachments>;

  constructor(
    private store: Store<{ attachments: TAttachments }>,
    private customFieldsService: CustomFieldsService,
    private galleryOverlayService: GalleryOverlayService,
    private promptService: PromptService,
    private requestService: RequestService,
    private usersService: UsersService,
    private pointsHelperService: PointsHelperService,
    private translationPipe: TranslationPipe,
    private pointActivityMergeService: PointActivityMergeService,
  ) {
    this.attachments$ = this.store.pipe(select(EStore.ATTACHMENTS));
  }

  ngOnInit() {
    this.attachments$.pipe(takeUntil(this.destroy$)).subscribe((attachments) => {
      this.media = attachments.media;
    });

    this.allUsers = this.usersService.getUsers();

    this.stringChange = this.ppChange as TActivityResponseChange<string>;
    this.stringsChange = this.ppChange as TActivityResponseChange<string[]>;
  }

  ngOnChanges() {
    const customFields = this.customFieldsService.getCustomFields();
    const workspaceCustomFields = customFields?.[this.ppWorkspaceId];
    const matchingCF = workspaceCustomFields?.[this.ppCustomFieldId];

    if (matchingCF) {
      this.type = matchingCF.type;
    }

    if (matchingCF?.volyIntegrationActive === EIntegrationStatus.DISABLED) {
      this.type = EIntegrationStatus.DISABLED;
    } else if (matchingCF?.volyIntegrationActive === EIntegrationStatus.ON) {
      this.type = EIntegrationStatus.ON;
    }

    if (
      this.ppChange.propName !== EActivityPropName.PIN &&
      this.ppChange.propName !== EActivityPropName.TITLE &&
      this.ppChange.propName !== EActivityPropName.DESCRIPTION &&
      this.ppChange.propName !== EActivityPropName.DESCRIPTION_RICH &&
      this.ppChange.propName !== EActivityPropName.TAGS &&
      this.ppChange.propName !== EActivityPropName.IMAGES &&
      this.ppChange.propName !== EActivityPropName.VIDEOS &&
      this.ppChange.propName !== EActivityPropName.DOCUMENTS &&
      this.ppChange.propName !== EActivityPropName.IMAGES_360 &&
      this.ppChange.propName !== EActivityPropName.ASSIGNEES &&
      this.ppChange.propName !== EActivityPropName.FLAGGED &&
      this.type !== ECustomFieldType.RICHTEXT
    ) {
      this.ppChange.generic = true;
    }

    if (
      this.ppChange.propName === EActivityPropName.DESCRIPTION ||
      this.type === ECustomFieldType.RICHTEXT
    ) {
      this.showMore = false;
    }

    if (this.ppChange.propName === EActivityPropName.TAGS) {
      const change = this.ppChange as TActivityResponseChange<string[]>;

      change.added = difference(change.newValue, change.oldValue);
      change.deleted = difference(change.oldValue, change.newValue);
    } else if (this.ppChange.propName === EActivityPropName.ASSIGNEES) {
      const change = this.ppChange as TActivityResponseChange<string[]>;

      if (!change.added && !change.deleted) {
        this.pointActivityMergeService.checkAndInitAssigneesChanges(change);
      }
    } else if (
      this.ppChange.propName === EActivityPropName.IMAGES ||
      this.ppChange.propName === EActivityPropName.VIDEOS ||
      this.ppChange.propName === EActivityPropName.DOCUMENTS ||
      this.ppChange.propName === EActivityPropName.IMAGES_360
    ) {
      const change = this.ppChange as TActivityResponseChange<TFileChangeInfo[]>;

      change.added = differenceBy(change.newValue, change.oldValue, 'id');

      if (!change.deleted) {
        change.deleted = differenceBy(change.oldValue, change.newValue, 'id');
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  statusPriorityToColor(statusPriority: string): string {
    return this.pointsHelperService.statusPriorityToColor(statusPriority);
  }

  humanizeActivityChange(activityChange: string): string {
    return this.pointsHelperService.humanizeActivityChange(activityChange);
  }

  openGallery(imageId: string): void {
    if (this.media.attachments[imageId]) {
      const rotationInProgress = this.requestService.isRequestOfTypeInProcess('imageRotate');

      if (rotationInProgress) {
        const promptText = this.translationPipe.transform(
          'prompt_attachments_rotation_in_progress',
        );

        this.promptService.showWarning(promptText);

        return;
      }

      this.galleryOverlayService.navigateToAttachment(imageId);
    }
  }
}
