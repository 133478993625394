export enum EActivityPropName {
  ASSIGNEES = 'assignees',
  POINT_COPY = 'pointCopy',
  POINT_MOVE = 'pointMove',
  ADDITIONAL_PINS = 'additionalPins',
  POLYGONS = 'polygons',
  PINS = 'pins',
  PIN = 'pin',
  TITLE = 'title',
  DESCRIPTION = 'description',
  DESCRIPTION_RICH = 'descriptionRich',
  TAGS = 'tags',
  DOCUMENTS = 'documents',
  IMAGES_360 = 'images360',
  VIDEOS = 'videos',
  IMAGES = 'images',
  POINT_COPY_FROM = 'pointCopyFrom',
  POINT_COPY_TO = 'pointCopyTo',
  POINT_MOVE_FROM = 'pointMoveFrom',
  POINT_MOVE_TO = 'pointMoveTo',
  FLAGGED = 'flagged',
  CUSTOM_FIELD_SUB_VALUE = 'customFieldSubValue',
}
