<section class="numericFieldWithSubfields" #subfieldsElement>
  <section class="numericFieldWithSubfieldsTotal">
    <pp-custom-fields-numeric-with-subfields-total
      [ppValue]="ppPointField.value"
      [ppFieldId]="ppFieldId"
      [ppWorkspaceId]="ppWorkspaceId"
      [ppUnit]="unit"
      [ppUnitTooltip]="unitSymbolTooltip"
    ></pp-custom-fields-numeric-with-subfields-total>

    <pp-custom-field-numeric-with-subfields-expand-collapse
      [ppDisabled]="subfields.length === 0"
      [ppCollapsed]="collapsed"
      [ppNumberOfFields]="subfields.length"
      (ppToggle)="toggleCollapse()"
    ></pp-custom-field-numeric-with-subfields-expand-collapse>
  </section>

  <ng-container *ngFor="let subfield of subfields; let index = index; trackBy: subfieldTrackByName">
    <pp-custom-field-numeric-with-subfields-subfield
      *ngIf="!collapsed || subfield.ppNew"
      [ppUnit]="unit"
      [ppSubfield]="subfield"
      [ppCanEdit]="ppCanEdit"
      (ppUpdate)="updateField($event, index)"
      (ppDelete)="deleteSubfield(index)"
      [ppDecimalPlaces]="+customField.decimalPlaces"
      [ppUnitTooltip]="unitSymbolTooltip"
    ></pp-custom-field-numeric-with-subfields-subfield
  ></ng-container>

  <pp-custom-fields-numeric-with-subfields-add-subfield-button
    *ngIf="ppCanEdit"
    [ppDisabled]="addSubfieldButtonDisabled"
    (ppAction)="addSubfield()"
    [ppType]="customField.type"
  ></pp-custom-fields-numeric-with-subfields-add-subfield-button>
</section>
