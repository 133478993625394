import { Component } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { TShare } from 'src/app/project/modules/share/share.model';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EditAccountUserModalDataService } from '../../edit-account-user-modal-data.service';
import { TSharesByWorkspace } from '../../edit-account-user-modal.consts';

@Component({
  selector: 'pp-account-user-modal-shares-list-bulk-share',
  templateUrl: './account-user-modal-shares-list-bulk-share.component.html',
  styleUrl: './account-user-modal-shares-list-bulk-share.component.scss',
})
export class AccountUserModalSharesListBulkShareComponent {
  bulkShare: TShare;
  EUserRole = EUserRole;
  EIconPath = EIconPath;

  constructor(private editAccountUserModalDataService: EditAccountUserModalDataService) {
    this.setInitialData();
    this.checkIfSharesMatch();

    this.editAccountUserModalDataService.sharesBulkChange$.subscribe(() => {
      this.checkIfSharesMatch();
    });
  }

  updateShareRole(): void {
    this.editAccountUserModalDataService.bulkChangeRole(this.bulkShare);
  }

  updateShareTags(): void {
    this.editAccountUserModalDataService.bulkChangeTags(this.bulkShare);
  }

  updateShareActivity(): void {
    this.editAccountUserModalDataService.bulkChangeActivity(this.bulkShare);
  }

  updateShareExports(): void {
    this.editAccountUserModalDataService.bulkChangeExports(this.bulkShare);
  }

  updateShareComments(): void {
    this.editAccountUserModalDataService.bulkChangeComments(this.bulkShare);
  }

  setRole(shares: TSharesByWorkspace): void {
    const roles = Object.values(shares).map((share) => share.shareOption);
    const role = roles.every((r) => r === roles[0]) ? roles[0] : null;

    this.bulkShare = {
      ...this.bulkShare,
      shareOption: role,
    };
  }

  setTags(shares: TSharesByWorkspace): void {
    const tags = Object.values(shares).map((share) => share.advancedAccessLevels.tags.permission);
    const tag = tags.every((t) => isEqual(t, tags[0]))
      ? cloneDeep(tags[0])
      : {
          read: null,
          edit: null,
        };

    this.bulkShare.advancedAccessLevels.tags.permission = { ...tag };
  }

  setActivity(shares: TSharesByWorkspace): void {
    const activities = Object.values(shares).map(
      (share) => share.advancedAccessLevels.timeline.permission,
    );
    const activity = activities.every((a) => isEqual(a, activities[0]))
      ? cloneDeep(activities[0])
      : {
          read: null,
        };

    this.bulkShare.advancedAccessLevels.timeline.permission = { ...activity };
  }

  setExports(shares: TSharesByWorkspace): void {
    const exports = Object.values(shares).map(
      (share) => share.advancedAccessLevels.exports.permission,
    );
    const exportPermission = exports.every((e) => isEqual(e, exports[0]))
      ? cloneDeep(exports[0])
      : {
          read: null,
        };

    this.bulkShare.advancedAccessLevels.exports.permission = { ...exportPermission };
  }

  setComments(shares: TSharesByWorkspace): void {
    const comments = Object.values(shares).map(
      (share) => share.advancedAccessLevels.timeline.comments.permission,
    );
    const comment = comments.every((c) => isEqual(c, comments[0]))
      ? cloneDeep(comments[0])
      : {
          read: null,
          edit: null,
        };

    this.bulkShare.advancedAccessLevels.timeline.comments.permission = { ...comment };
  }

  private checkIfSharesMatch(): void {
    const shares = this.editAccountUserModalDataService.getSharesMap();

    if (Object.keys(shares).length === 0) {
      return;
    }

    this.setRole(shares);
    this.setTags(shares);
    this.setActivity(shares);
    this.setExports(shares);
    this.setComments(shares);
  }

  private setInitialData(): void {
    this.bulkShare = {
      shareOption: null,
      tagLimited: false,
      advancedAccessLevels: {
        tags: {
          permission: {
            read: null,
            edit: null,
          },
        },
        timeline: {
          comments: {
            permission: {
              read: null,
              edit: null,
            },
          },
          permission: {
            read: null,
          },
        },
        sitePlan: {
          permission: {
            read: null,
          },
        },
        exports: {
          permission: {
            read: null,
          },
        },
        customFields: [],
      },
    } as Partial<TShare> as TShare;
  }
}
