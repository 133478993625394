import { Component } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';

@Component({
  selector: 'pp-calculator-icon',
  templateUrl: './calculator-icon.component.html',
  styleUrl: './calculator-icon.component.scss',
})
export class CalculatorIconComponent {
  EIconPath = EIconPath;
}
