import { HttpClient } from '@angular/common/http';
import { effect, Injectable, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import * as ExcelJS from 'exceljs';
import { cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';
import { DownloadService } from '../../services/download.service';
import { EStore } from '../../shared/enums/store.enum';
import { TColumn } from '../site/site-table/columns/column.model';
import { TWhiteLabelStore } from '../white-label/white-label.model';
import { generateExcelHeaders } from './local/generate-excel-headers';
import { TColumnFormatMap } from './point-export-local.service';

@Injectable({
  providedIn: 'root',
})
export class PointExportXLSXService {
  private excel_headers: string[];
  private whiteLabel: TWhiteLabelStore;
  private whiteLabelSignal: Signal<TWhiteLabelStore>;

  constructor(
    private http: HttpClient,
    private downloadService: DownloadService,
    private store: Store<{ whiteLabel: TWhiteLabelStore }>,
  ) {
    this.whiteLabelSignal = toSignal(this.store.select(EStore.WHITE_LABEL), {
      initialValue: { data: null, created: null, accountId: null },
    });

    effect(() => {
      const whiteLabel = this.whiteLabelSignal();
      this.whiteLabel = cloneDeep(whiteLabel);
    });
  }

  async exportWorksheet(
    tableToExport: string[][],
    visibleColumns: TColumn[],
    exportName: string,
    groupDepth: number,
    columnFormatMap: TColumnFormatMap,
    accountName: string,
    siteName: string,
    userName: string,
    sheetName: string,
    exportFileName: string,
  ): Promise<void> {
    let workbook = new ExcelJS.Workbook();

    let worksheet = workbook.addWorksheet(sheetName, {
      views: [
        {
          state: 'frozen',
          ySplit: 4,
          showGridLines: false,
          zoomScale: 100,
        },
      ],
    });

    const urlLogo = this.whiteLabel?.data?.logoDark
      ? 'api/v1/images/' + this.whiteLabel?.data?.logoDark + '/file/size/bounded/400'
      : 'assets/images/logos/pinpoint-logo.png';

    const imageData = await firstValueFrom(this.http.get(urlLogo, { responseType: 'arraybuffer' }));

    const imageId = workbook.addImage({
      buffer: new Uint8Array(imageData),
      extension: 'png',
    });

    worksheet.addRows(tableToExport);

    this.formatExcelCols(visibleColumns, worksheet, columnFormatMap, groupDepth);
    this.styleRow(worksheet, groupDepth);

    this.addHeadingAndFooter(
      worksheet,
      imageId,
      tableToExport,
      groupDepth,
      accountName,
      siteName,
      userName,
    );

    this.setColumnWidths(worksheet, visibleColumns, groupDepth);

    worksheet.properties.showGridLines = false;

    workbook.xlsx.writeBuffer().then((data) => {
      const exportData = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      this.downloadService.saveFile(exportData, exportFileName);
    });
  }

  formatExcelCols(
    columns: TColumn[],
    worksheet: ExcelJS.Worksheet,
    columnFormatMap: TColumnFormatMap,
    groupDepth: number,
  ): ExcelJS.Worksheet {
    this.excel_headers = generateExcelHeaders(columns.length + groupDepth);
    for (let i = groupDepth; i < columns.length + groupDepth; i++) {
      if (columnFormatMap[i]) {
        worksheet.getColumn(this.excel_headers[i]).numFmt = columnFormatMap[i].format;
      }
    }

    return worksheet;
  }

  setColumnWidths(
    worksheetColumns: ExcelJS.Worksheet,
    visibleColumns: TColumn[],
    groupDepth: number,
  ): void {
    let originalOffset = 1;

    //Width of group columns
    for (let i = originalOffset; i <= groupDepth; i++) {
      worksheetColumns.getColumn(i).width = 12.5;
    }

    originalOffset += groupDepth;

    //Width of the visible columns

    for (let i = 0; i <= visibleColumns.length - 1; i++) {
      worksheetColumns.getColumn(i + originalOffset).width = visibleColumns[i].width / 5;
    }

    //Width of the url column
    worksheetColumns.getColumn(visibleColumns.length + originalOffset).width = 90;
  }

  private addHeadingAndFooter(
    worksheet: ExcelJS.Worksheet,
    imageId: number,
    tableToExport: string[][],
    groupDepth: number,
    accountName: string,
    siteName: string,
    userName: string,
  ): void {
    const grey700 = '00344054';
    const rowColor = this.whiteLabel.data
      ? '00' + this.whiteLabel.data.primary700.split('#')[1]
      : grey700;

    worksheet.getRow(1).eachCell((cell, colNumber) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: rowColor },
      };
      cell.font = {
        color: { argb: 'ffffffff' },
        bold: true,
      };
    });

    let l = (length = tableToExport[0].length + groupDepth);

    let emptyHeader0 = new Array(l - groupDepth);
    emptyHeader0.fill('');
    let accountNameHeader = new Array(l);
    accountNameHeader.fill('');
    accountNameHeader[0] = accountName;
    let siteNameHeader = new Array(l);
    siteNameHeader.fill('');
    siteNameHeader[0] = siteName;

    worksheet.insertRows(1, [emptyHeader0, accountNameHeader, siteNameHeader]).forEach((row) => {
      row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffffff' } };
    });
    worksheet.getRow(1).height = 30;
    // @ts-ignore
    worksheet.addImage(imageId, { ext: { width: 30, height: 30 } });
    worksheet.getRow(2).font = {
      bold: true,
    };

    // add footer

    let emptyFooter0 = new Array(l - groupDepth);
    emptyFooter0.fill('');
    let emptyFooter1 = new Array(l);
    emptyFooter1[l - groupDepth] = userName;
    let emptyFooter2 = new Array(l);
    emptyFooter2[l - groupDepth] = this.getDateTime();

    worksheet.addRows([emptyFooter0, emptyFooter1, emptyFooter2]).forEach((row) => {
      row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffffffff' } };
    });

    worksheet.getRow(worksheet.rowCount - 2).eachCell((cell, colNumber) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: rowColor },
      };
    });
  }

  private styleRow(worksheet: ExcelJS.Worksheet, groupDepth: number): void {
    const groupRowColor = this.whiteLabel.data
      ? 'ff' + this.whiteLabel.data.primary100.split('#')[1]
      : 'ffeaecf0';

    worksheet.getRows(2, worksheet.rowCount - 1).forEach((row) => {
      let rowIsGroup = false;

      for (let i = 1; i <= groupDepth; i++) {
        if (row.getCell(i).value) {
          rowIsGroup = true;
          break;
        }
      }

      const fill = rowIsGroup ? groupRowColor : 'ffffffff';

      for (let i = 1; i <= worksheet.columnCount; i++) {
        let cell = row.getCell(i);
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: fill },
        };
        cell.border = {
          top: { style: 'thin', color: { argb: 'ffd0d5dd' } },
          left: { style: 'thin', color: { argb: 'ffd0d5dd' } },
          bottom: { style: 'thin', color: { argb: 'ffd0d5dd' } },
          right: { style: 'thin', color: { argb: 'ffd0d5dd' } },
        };
      }
    });
  }

  private getDateTime(): string {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }
}
