import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ECustomFieldType } from '../../custom-field-types-enums';

@Component({
  selector: 'pp-custom-fields-numeric-with-subfields-add-subfield-button',
  templateUrl: './custom-fields-numeric-with-subfields-add-subfield-button.component.html',
  styleUrl: './custom-fields-numeric-with-subfields-add-subfield-button.component.scss',
})
export class CustomFieldsNumericWithSubfieldsAddSubfieldButtonComponent implements OnChanges {
  @Input() ppType: ECustomFieldType;
  @Input() ppDisabled: boolean;
  @Output() ppAction: EventEmitter<void> = new EventEmitter<void>();

  addSubfieldText: string;

  constructor(private translationPipe: TranslationPipe) {}

  ngOnChanges(): void {
    switch (this.ppType) {
      case ECustomFieldType.COST:
        this.addSubfieldText = '+ ' + this.translationPipe.transform('add_cost');
        break;
      case ECustomFieldType.PERCENTAGE:
        this.addSubfieldText = '+ ' + this.translationPipe.transform('add_percentage');
        break;
      case ECustomFieldType.NUMBERS:
        this.addSubfieldText = '+ ' + this.translationPipe.transform('add_numbers');
        break;
    }
  }

  addSubfield(): void {
    if (this.ppDisabled) {
      return;
    }

    this.ppAction.emit();
  }
}
