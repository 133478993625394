import { Component, Input, OnChanges } from '@angular/core';
import { EIconPath } from '../../shared/enums/icons.enum';
import { TIconColor } from './icon-colors.model';

@Component({
  selector: 'pp-icon',
  templateUrl: './icon.component.html',
  host: {
    '[class.displayContents]': 'ppDisplayContents',
  },
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges {
  @Input() ppSrc: EIconPath;
  @Input() ppColor: TIconColor;
  @Input() ppClass: string;
  @Input() ppDisplayContents = false;
  @Input() ppHoverColor: TIconColor;
  @Input() ppOverrideWidth: number;
  @Input() ppOverrideHeight: number;

  iconClass: string;
  hoverClass: string;
  classList = '';
  svgStyle: {
    [key: string]: string;
  } = {};
  private defaultIconColorClass = '';

  constructor() {}

  ngOnChanges(): void {
    this.classList = '';

    if (this.ppClass) {
      this.classList += ' ' + this.ppClass;
    }

    if (this.ppDisplayContents) {
      this.classList += ' displayContents';
    }

    this.iconClass = this.getColorClass(this.ppColor);
    this.hoverClass = this.getColorClass(this.ppHoverColor, this.iconClass) + '--hover';

    this.classList += ' ' + this.iconClass;
    this.classList += ' ' + this.hoverClass;

    this.setSvgStyle();
  }

  private setSvgStyle(): void {
    const svgStyle: {
      [key: string]: string;
    } = {};

    if (this.ppOverrideWidth) {
      svgStyle.width = this.ppOverrideWidth + 'px';
    }

    if (this.ppOverrideHeight) {
      svgStyle.height = this.ppOverrideHeight + 'px';
    }

    this.svgStyle = svgStyle;
  }

  private getColorClass(
    color: TIconColor,
    defaultClass: string = this.defaultIconColorClass,
  ): string {
    switch (color) {
      case 'inherit':
        return 'icon--inherit';
      case 'white':
        return 'icon--white';
      case 'red':
        return 'icon--red';
      case 'grey-100':
        return 'icon--grey-100';
      case 'grey-200':
        return 'icon--grey-200';
      case 'grey-300':
        return 'icon--grey-300';
      case 'grey-400':
        return 'icon--grey-400';
      case 'grey-500':
        return 'icon--grey-500';
      case 'grey-600':
        return 'icon--grey-600';
      case 'grey-700':
        return 'icon--grey-700';
      case 'grey-900':
        return 'icon--grey-900';
      case 'primary-400':
        return 'icon--primary-400';
      case 'primary-500':
        return 'icon--primary-500';
      case 'primary-600':
        return 'icon--primary-600';
      case 'primary-700':
        return 'icon--primary-700';
      default:
        return defaultClass;
    }
  }
}
