import { Injectable, OnDestroy } from '@angular/core';
import differenceBy from 'lodash/differenceBy';
import differenceWith from 'lodash/differenceWith';

import { TWorkspacePreferences } from '../../preferences/preferences.model';

import { Subject, throwError } from 'rxjs';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/project/modules/account/account-service/account.service';
import { ECustomFieldType } from 'src/app/project/modules/custom-fields/custom-field-types-enums';
import { EUserRole } from 'src/app/project/modules/share/share-utils/user-roles';
import { WorkspaceService } from 'src/app/project/modules/workspace/workspace.service';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { PreferencesService } from '../../preferences/preferences-service/preferences.service';
import { SavedViewsService } from '../../saved-views/saved-views.service';
import { EDefaultColumnWidths } from './columns/default-column-widths-enum';
import {
  SiteTableColumnsDataService,
  TColumnsData,
  TSingleColumnData,
} from './site-table-columns-data.service';

type TUpdateWorkspacePreferencesData = {
  body: TWorkspacePreferences;
  workspaceId: string;
};

@Injectable({
  providedIn: 'root',
})
export class SiteTableColumnsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly updateColumnPrefs$ = new Subject<TUpdateWorkspacePreferencesData>();
  private readonly updateColumnPrefsDebounceTimeMs = 2000;

  private columns: TColumnsData = this.siteTableColumnsDataService.getColumns();
  private columnsAll: TColumnsData = this.siteTableColumnsDataService.getAllColumns();

  private costFields: string[] = [];

  constructor(
    private preferencesService: PreferencesService,
    private siteTableColumnsDataService: SiteTableColumnsDataService,
    private activeService: ActiveService,
    private workspaceService: WorkspaceService,
    private customFieldsService: CustomFieldsService,
    private accountService: AccountService,
    private savedViewsService: SavedViewsService,
  ) {
    this.updateColumnPrefs$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.updateColumnPrefsDebounceTimeMs),
        tap((data) => {
          this.updateWorkspacePreferences(data);
        }),
      )
      .subscribe();
  }

  getCostFields(): string[] {
    const workspace = this.workspaceService.findWorkspace(
      this.activeService.getActiveWorkspaceId(),
    );
    const customFields = this.customFieldsService.getCustomFields();

    if (workspace) {
      this.costFields = workspace.customFields.filter(
        (fieldId) => customFields[workspace.workspaceId][fieldId].type === ECustomFieldType.COST,
      );

      return this.costFields;
    }
  }

  updateColumnPrefs(): void {
    const preferences = this.preferencesService.getPreferences();
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const accountId = this.activeService.getActiveAccountId();
    const account = this.accountService.getAccount(accountId);
    const workspace = this.workspaceService.findWorkspace(workspaceId);
    const columns = this.siteTableColumnsDataService.getColumns();

    let globalSitePreferences = false;

    if (
      account &&
      account.accountFeatures &&
      typeof account.accountFeatures.globalSitePreferences !== 'undefined'
    ) {
      globalSitePreferences = account.accountFeatures.globalSitePreferences;
    }

    if (
      (workspaceId &&
        workspaceId !== 'null' &&
        workspaceId !== 'undefined' &&
        !globalSitePreferences) ||
      (globalSitePreferences &&
        workspace &&
        (workspace.share.shareOption === EUserRole.SITE_ADMIN ||
          workspace.share.shareOption === EUserRole.ACCOUNT_ADMIN ||
          workspace.share.shareOption === EUserRole.OWNER))
    ) {
      const body: TWorkspacePreferences = preferences.workspaces[workspaceId];

      body.columns = this.columns.data;

      if (columns.sorting?.column) {
        body.columns[columns.sorting.column].sortOrder = columns.sorting.sortOrder;
      }

      if (preferences.workspaces && preferences.workspaces[workspaceId]) {
        preferences.workspaces[workspaceId].columns = [...this.columns.data];
      } else if (preferences.workspaces) {
        preferences.workspaces[workspaceId] = {
          columns: [...this.columns.data],
        };
      } else {
        preferences.workspaces = {
          [workspaceId]: {
            columns: [...this.columns.data],
          },
        };
      }

      this.updateColumnPrefs$.next({ body, workspaceId });
    }
  }

  initColumns(reset?: boolean): TWorkspacePreferences {
    const preferences = this.preferencesService.getPreferences();
    const workspaceId = this.activeService.getActiveWorkspaceId();
    const workspace = this.workspaceService.findWorkspace(workspaceId);
    const customFields = this.customFieldsService.getCustomFields();
    const savedViews = this.savedViewsService.getSavedViews(workspaceId);

    const currentWorkspacePrefs = this.preferencesService.getCurrentWorkspacePrefs({
      preferences,
      workspaceId,
      savedViews,
    });

    if (
      !reset &&
      currentWorkspacePrefs &&
      currentWorkspacePrefs.columns &&
      currentWorkspacePrefs.columns.length > 0
    ) {
      this.columns.data.length = 0;
      this.columns.colWidths.length = 0;

      this.columns.data = [...currentWorkspacePrefs.columns];

      if (!this.columns.data) {
        this.columns.data = [];
      }

      // Fix for when there was no description column
      const descriptionColumn = this.columns.data.findIndex(
        (column) => column.name === 'Description',
      );

      if (descriptionColumn === -1) {
        const descriptionColumnData: TSingleColumnData = {
          name: 'Description',
          index: this.columns.data.length + 1,
          hidden: false,
        };

        this.columns.data.push(descriptionColumnData);
      }

      // Fix for when there was no red flag by column
      const redFlagIndex = this.columns.data.findIndex((column) => column.name === 'Flag');

      if (redFlagIndex === -1) {
        const tagsIndex = this.columns.data.findIndex((column) => column.name === 'Flag');

        const redFlagColumnsData: TSingleColumnData = {
          name: 'Flag',
          index: this.columns.data.length + 1,
          hidden: false,
        };

        this.columns.data.splice(tagsIndex, 0, redFlagColumnsData);
      }

      // Fix for when there was no created by column
      const createdByIndex = this.columns.data.findIndex((column) => column.name === 'Created By');

      if (createdByIndex === -1) {
        const tagsIndex = this.columns.data.findIndex((column) => column.name === 'Tags');

        const createdByColumnsData: TSingleColumnData = {
          name: 'Created By',
          index: this.columns.data.length + 1,
          hidden: false,
        };

        this.columns.data.splice(tagsIndex, 0, createdByColumnsData);
      }

      // Fix for when there was no assignees by column
      const assigneesIndex = this.columns.data.findIndex((column) => column.name === 'Assignees');

      if (assigneesIndex === -1) {
        const tagsIndex = this.columns.data.findIndex((column) => column.name === 'Tags');

        const assigneesColumnsData: TSingleColumnData = {
          name: 'Assignees',
          index: this.columns.data.length + 1,
          hidden: false,
        };

        this.columns.data.splice(tagsIndex, 0, assigneesColumnsData);
      }

      this.checkForNewColumns(workspaceId, { isOverview: false });

      // init hidden columns

      const columnsFilteredHidden = this.columns.data.filter((column) => column.hidden);
      const hiddenColumns = columnsFilteredHidden.map((column) => column.index);

      this.siteTableColumnsDataService.setColumns({ hidden: hiddenColumns });

      // init sorting columns

      const sortByColumn = this.columns.data.find((column) => column.sortOrder !== undefined);

      if (sortByColumn && sortByColumn.index > -1 && sortByColumn.sortOrder !== undefined) {
        if (sortByColumn.sortOrder) {
          this.columns.sorting.sortOrder = sortByColumn.sortOrder;
        } else {
          this.columns.sorting.sortOrder = 'DESC';
        }

        this.columns.sorting.column = sortByColumn.index;
      } else {
        this.setDefaultSorting(currentWorkspacePrefs);
      }
    } else {
      this.columns.data.length = 0;
      this.columns.colWidths.length = 0;

      const columns = this.columns.default.slice();

      if (workspace) {
        workspace.customFields.forEach((fieldId) => {
          columns.push({
            name: customFields[workspace.workspaceId][fieldId].label,
          });
        });
      }

      this.columns.data = columns.map((column, index) => {
        const newColumn: TSingleColumnData = {
          name: column.name,
          index,
          hidden: false,
          sortOrder: undefined,
          width: undefined,
        };

        return newColumn;
      });
    }

    let body: TWorkspacePreferences = null;

    if (
      workspaceId &&
      workspaceId !== 'null' &&
      workspaceId !== 'undefined' &&
      currentWorkspacePrefs
    ) {
      body = currentWorkspacePrefs;

      body.columns = [...this.columns.data];
    }

    this.getCostFields();
    return body;
  }

  initAllColumns(): void {
    const preferences = this.preferencesService.getPreferences();
    const workspaces = this.workspaceService.getWorkspaces();

    if (preferences && preferences.workspaces) {
      this.columnsAll.data = [
        { name: 'Account', index: 0 },
        { name: 'Site', index: 1 },
        { name: 'Priority', index: 2 },
        { name: 'Status', index: 3 },
        { name: 'ID', index: 4 },
        { name: 'Title', index: 5 },
        { name: 'Description', index: 6 },
        { name: 'Created', index: 7 },
        { name: 'Updated', index: 8 },
        { name: 'Tags', index: 9 },
        { name: 'Created By', index: 10 },
        { name: 'Assignees', index: 11 },
      ];
    }

    // Fix for when there was no description column
    const descriptionColumn = this.columnsAll.data.findIndex(
      (column) => column.name === 'Description',
    );

    if (descriptionColumn === -1) {
      const descriptionColumnData: TSingleColumnData = {
        name: 'Description',
        index: this.columns.data.length + 1,
        hidden: false,
      };

      this.columnsAll.data.push(descriptionColumnData);
    }

    Object.keys(workspaces).forEach((workspaceId) => {
      this.checkForNewColumns(workspaceId, { isOverview: true });
    });

    // init hidden columns

    const columnsFilteredHidden = this.columnsAll.data.filter((column) => column.hidden);
    const hiddenColumns = columnsFilteredHidden.map((column) => column.index);

    this.siteTableColumnsDataService.setColumns({ hidden: hiddenColumns });

    // init sorting columns

    const sortByColumn = this.columnsAll.data.find((column) => column.sortOrder !== undefined);

    if (sortByColumn && sortByColumn.index > -1 && sortByColumn.sortOrder !== undefined) {
      if (sortByColumn.sortOrder) {
        this.columnsAll.sorting.sortOrder = sortByColumn.sortOrder;
      } else {
        this.columnsAll.sorting.sortOrder = 'DESC';
      }

      this.columnsAll.sorting.column = sortByColumn.index;
    }

    this.getCostFields();
  }

  checkForNewColumns(
    workspaceId: string,
    {
      isOverview = false,
    }: {
      isOverview?: boolean;
    },
  ): void {
    const workspace = this.workspaceService.findWorkspace(workspaceId);

    let oldCustomFieldColumns = null;
    let newCustomFieldIds = null;

    if (workspace) {
      const customFields = this.customFieldsService.getCustomFields();

      let customFieldColumns = differenceBy(this.columns.data, this.columns.default, 'name');

      if (isOverview) {
        customFieldColumns = differenceBy(this.columnsAll.data, this.columns.default, 'name');

        oldCustomFieldColumns = differenceWith(
          customFieldColumns,
          workspace.customFields,
          (column, fieldId) =>
            column.name === customFields[workspace.workspaceId]?.[fieldId]?.label,
        );

        newCustomFieldIds = differenceWith(
          workspace.customFields,
          customFieldColumns,
          (fieldId, column) =>
            column.name === customFields[workspace.workspaceId]?.[fieldId]?.label,
        );

        if (oldCustomFieldColumns?.length > 0) {
          oldCustomFieldColumns.forEach((column) => {
            const index = this.columnsAll.data.findIndex(
              (columnData) => columnData.name === column.name,
            );

            this.columns.data.splice(index, 1);
          });
        }
      } else {
        oldCustomFieldColumns = differenceWith(
          customFieldColumns,
          workspace.customFields,
          (column, fieldId) =>
            column.name === customFields[workspace.workspaceId]?.[fieldId]?.label,
        );

        newCustomFieldIds = differenceWith(
          workspace.customFields,
          customFieldColumns,
          (fieldId, column) =>
            column.name === customFields[workspace.workspaceId]?.[fieldId]?.label,
        );

        const oldPriorityColumnIndex = oldCustomFieldColumns.findIndex(
          (column) => column.name === 'P',
        );

        if (oldPriorityColumnIndex !== -1) {
          oldCustomFieldColumns.splice(oldPriorityColumnIndex, 1);
        }

        const oldStatusColumnIndex = oldCustomFieldColumns.findIndex(
          (column) => column.name === 'S',
        );

        if (oldStatusColumnIndex !== -1) {
          oldCustomFieldColumns.splice(oldStatusColumnIndex, 1);
        }

        if (oldCustomFieldColumns?.length > 0) {
          oldCustomFieldColumns.forEach((column) => {
            const index = this.columns.data.findIndex(
              (columnData) => columnData.name === column.name,
            );

            this.columns.data.splice(index, 1);
          });
        }
      }

      if (newCustomFieldIds?.length > 0) {
        if (isOverview) {
          const maxIndex = Math.max(...this.columnsAll.data.map((o) => o.index));

          newCustomFieldIds.forEach((fieldId, index) => {
            const newColumn: TSingleColumnData = {
              name: customFields[workspace.workspaceId][fieldId].label,
              index: maxIndex + index + 1,
              hidden: false,
              sortOrder: undefined,
              width: EDefaultColumnWidths.CUSTOM_FIELD,
              workspaceId: workspace.workspaceId,
              fieldId,
            };

            const existingField = this.columnsAll.data.find(
              (_column) =>
                _column.name === newColumn.name &&
                customFields[_column.workspaceId]?.[_column.fieldId]?.currencyCode ===
                  customFields?.[newColumn.workspaceId]?.[newColumn.fieldId]?.currencyCode &&
                customFields[_column.workspaceId]?.[_column.fieldId]?.subValuesActive ===
                  customFields?.[newColumn.workspaceId]?.[newColumn.fieldId]?.subValuesActive,
            );

            if (!existingField) {
              this.columnsAll.data.push(newColumn);
            }
          });
        } else {
          const maxIndex = Math.max(...this.columns.data.map((o) => o.index));

          newCustomFieldIds.forEach((fieldId, index) => {
            const newColumn: TSingleColumnData = {
              name: customFields[workspace.workspaceId][fieldId].label,
              index: maxIndex + index + 1,
              hidden: false,
              sortOrder: undefined,
              width: EDefaultColumnWidths.CUSTOM_FIELD,
            };

            this.columns.data.push(newColumn);
          });
        }
      }
    }
  }

  setDefaultSorting(currentWorkspacePrefs: TWorkspacePreferences): void {
    const sortByColumn = currentWorkspacePrefs.columns.find((column) => column.name === 'Updated');

    if (sortByColumn) {
      this.columns.sorting.column = sortByColumn.index;
      this.columns.sorting.sortOrder = 'DESC';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private updateWorkspacePreferences({ body, workspaceId }: TUpdateWorkspacePreferencesData): void {
    this.preferencesService
      .updateWorkspacePreferences(body, workspaceId)
      .pipe(
        catchError((error) => {
          const errorData = {
            message: 'Error while updating workspace preferences',
            error,
          };
          console.error(errorData);
          return throwError(error);
        }),
      )
      .subscribe();
  }
}
