<input
  #daysInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="days"
  placeholder="d"
  (ppFormatted)="updateDaysInput($event)"
  (keydown.enter)="blurInput($event)"
  *ngIf="!showHoursOnly"
/>

<input
  #hoursInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="hours"
  placeholder="h"
  (ppFormatted)="updateHoursInput($event)"
  (keydown.enter)="blurInput($event)"
/>

<input
  #minutesInput
  [ppAutonumeric]="autonumericOptions"
  class="pointCF__input pointCF__input--time"
  [(ngModel)]="minutes"
  placeholder="min"
  (ppFormatted)="updateMinutesInput($event)"
  (keydown.enter)="blurInput($event)"
/>
