import { Component } from '@angular/core';

@Component({
  selector: 'pp-add-custom-field-modal-import-formula-error',
  templateUrl: './add-custom-field-modal-import-formula-error.component.html',
  styleUrl: './add-custom-field-modal-import-formula-error.component.scss'
})
export class AddCustomFieldModalImportFormulaErrorComponent {

}
