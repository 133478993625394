import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { SortingService } from '@core/helpers';
import { Store, select } from '@ngrx/store';
import { TAllUsers } from '@project/view-models';
import { Observable, Subject, catchError, debounceTime, of, takeUntil, tap } from 'rxjs';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { TActive } from 'src/app/project/services/active/active.model';
import { ActiveService } from 'src/app/project/services/active/active.service';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { EStore } from 'src/app/project/shared/enums/store.enum';
import { AccountUsersTableService } from '../../account/account-settings/account-users-table/account-users-table.service';
import { TCombinedCustomField } from '../../custom-fields/combine-custom-fields/combined-custom-field.model';
import { logErrorInSentry } from '../../errors/response-error';
import { TSync } from '../../offline/sync.model';
import { SyncService } from '../../offline/sync.service';
import { AccountSharesService } from '../../share/account-shares.service';
import { TShare } from '../../share/share.model';
import { TUser } from '../../user/user.model';
import { UserService } from '../../user/user.service';
import { TAccountUser } from '../../users/account.user.model';
import { UsersService } from '../../users/users.service';
import { TWorkspace, TWorkspacesById } from '../../workspace/workspace.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { SelectedSharesService } from '../services/selected-shares.service';
import { AccountUserManagementService } from './account-user-management-service/account-user-management.service';
import { UserManagementUserSectionService } from './user-management-user-section/user-management-user-section.service';

@Component({
  selector: 'pp-account-user-management',
  templateUrl: './account-user-management.component.html',
  styleUrls: ['./account-user-management.component.scss'],
})
export class AccountUserManagementComponent implements OnDestroy {
  @ViewChild('tableElement') tableElement: CdkVirtualScrollViewport;

  private readonly destroy$ = new Subject<void>();
  private readonly synced$ = new Subject<void>();

  user: TUser;
  workspacesLoaded = false;
  workspaces: TWorkspacesById;
  accountId: string;
  users: TAllUsers;
  filteredUsers: TAccountUser[] = [];
  usersWithShares: TAccountUser[] = [];
  userIds: string[] = [];
  customFields: TCombinedCustomField[] = [];
  workspacesFetched = false;
  workspace: TWorkspace;
  EIconPath = EIconPath;
  dataFetched: boolean = false;
  private searchKeywordDebounceTime: number = 500;
  private active$: Observable<TActive>;

  user$: Observable<TUser>;
  private sync$: Observable<TSync>;

  constructor(
    private store: Store<{
      user: TUser;
      sync: TSync;
      active: TActive;
    }>,
    private workspaceService: WorkspaceService,
    private userService: UserService,
    private activeService: ActiveService,
    private usersService: UsersService,
    private sortingService: SortingService,
    private translationPipe: TranslationPipe,
    private accountUsersTableService: AccountUsersTableService,
    private promptService: PromptService,
    private syncService: SyncService,
    private accountUserManagementService: AccountUserManagementService,
    private accountSharesService: AccountSharesService,
    private selectedSharesService: SelectedSharesService,
    private userManagementUserSectionService: UserManagementUserSectionService,
    private ngZone: NgZone,
  ) {
    this.user$ = this.store.pipe(select(EStore.USER));
    this.sync$ = this.store.pipe(select(EStore.SYNC));
    this.active$ = this.store.pipe(select(EStore.ACTIVE));

    this.syncService.firstLoad().pipe(takeUntil(this.destroy$)).subscribe();

    this.sync$.pipe(takeUntil(this.synced$)).subscribe((sync) => {
      if (sync.synced) {
        this.synced$.next();

        this.listenToActiveStateChange();
        this.onAppSynced();
      }
    });

    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
    });

    this.accountSharesService.accountSharesChange$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.generateAccountData(false);
    });

    this.accountUserManagementService.userManagementSearchKeywordChange$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.searchKeywordDebounceTime),
        tap(() => {
          this.searchUsers(true);
        }),
      )
      .subscribe();
  }

  private listenToActiveStateChange(): void {
    this.active$.pipe(takeUntil(this.destroy$)).subscribe((active) => {
      this.accountId = this.activeService.getActiveAccountId();
      this.generateAccountData(true);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.userManagementUserSectionService.clearExpandedUsers();
    this.accountUserManagementService.setKeyword(null);
  }

  trackByFunction(index: number, item: TAccountUser): string {
    return item.userId;
  }

  private searchUsers(resetTable = false): void {
    const keyword = this.accountUserManagementService.getKeyword();
    this.filteredUsers = this.accountUsersTableService.searchAccountUsers(
      this.usersWithShares,
      keyword,
    );

    this.userManagementUserSectionService.setFilteredUsers(this.filteredUsers);

    if (resetTable) {
      this.resetTableScroll();
    } else {
      this.maintainTableScrollForSafariAndFirefox();
    }
  }

  private onAppSynced(): void {
    this.workspaceService
      .generateWorkspaces({
        showHidden: true,
      })
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.workspacesLoaded = true;
          this.user = this.userService.getUser();
          this.accountId = this.activeService.getActiveAccountId();
          this.generateAccountData(true);
          this.dataFetched = true;
        }),
      )
      .subscribe();
  }

  private generateAccountData(resetData: boolean): void {
    if (!this.accountId) {
      return;
    }

    this.getAccountDataObservable(resetData)
      .pipe(
        tap((response) => {
          this.accountUserManagementService
            .getAccountCustomFields(this.accountId)
            .pipe(
              tap((customFields) => {
                this.customFields = customFields;

                this.selectedSharesService.clearSelectedShares();
                this.sortAccountUsers(response);
                this.searchUsers(false);
              }),
            )
            .subscribe();
        }),
        catchError((error) => {
          const prompt = this.translationPipe.transform('prompt_account_shares_error');

          logErrorInSentry(error);
          this.promptService.showError(prompt);

          return of(error);
        }),
      )
      .subscribe();
  }

  private sortAccountUsers(response: TAccountUser[]): void {
    this.users = this.usersService.getUsers();
    this.workspaces = this.workspaceService.getWorkspaces();

    this.usersWithShares = response.sort((a, b) =>
      this.sortingService.naturalSort(
        this.users[a.userId] ? this.users[a.userId].userName : a.email,
        this.users[b.userId] ? this.users[b.userId].userName : b.email,
      ),
    );

    for (let i = 0; i < this.usersWithShares.length; i++) {
      this.usersWithShares[i].shares = this.sortAccountUsersSharesBySiteName(
        this.usersWithShares[i].shares,
      );
    }
  }

  sortAccountUsersSharesBySiteName(sites: TShare[]): TShare[] {
    return sites.sort((_a, _b) =>
      this.sortingService.naturalSort(
        this.getSiteName(_a.workspaceId),
        this.getSiteName(_b.workspaceId),
      ),
    );
  }

  private getSiteName(workspaceId: string): string {
    return this.workspaces[workspaceId]?.siteName || '';
  }

  private getAccountDataObservable(resetData: boolean) {
    if (resetData) {
      return this.accountSharesService.resetAccountShares(this.accountId);
    }

    return this.accountSharesService.fetchAccountShares(this.accountId);
  }

  private resetTableScroll() {
    this.tableElement.scrollTo({ left: 0 });
  }

  // maintainTableScrollForSafariAndFirefox is a Safari and Firefox workaround for scroll position reset
  private maintainTableScrollForSafariAndFirefox(): void {
    if (!this.tableElement) {
      return;
    }

    const top = this.tableElement.measureScrollOffset('top');

    this.tableElement.scrollTo({ top });

    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.tableElement.scrollTo({ top });
      });
    });
  }
}
