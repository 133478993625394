import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TColumn } from '../../../columns/column.model';
import { GET_COLUMNS } from '../../../columns/columns.store';

export function checkColumnsToAdd(customFields: TCustomField[]): TColumn[] {
  const columnsToAdd = [];

  const visibleCustomFields = customFields.filter((customField) => customField.display);

  visibleCustomFields.forEach((customField) => {
    const column = GET_COLUMNS().find(
      (searchedColumn) => searchedColumn.name === customField.label,
    );

    if (!column) {
      columnsToAdd.push({
        name: customField.label,
        showTotal: customField.showTotal === true,
        customFieldIds: [customField.id.toString()],
        unit: customField.unit,
        decimalPlaces: customField.decimalPlaces,
        currency: customField.currencyCode,
        subValuesActive: customField.subValuesActive,
      } as TColumn);
    }
  });

  return columnsToAdd;
}
