<div class="pointCF__field">
  <label
    ppTooltip
    class="pointCF__label"
    ppTitle="{{ ppCustomField?.label }}"
    [ppTrimTooltip]="true"
  >
    <img
      class="pointCF__bulk-changes-field-icon"
      [src]="EIconPath.CUSTOM_FIELD_PERCENTAGE_ACTIVITY"
    />

    <span class="pointCF__label-container">
      {{ ppCustomField?.label }}
    </span>
  </label>

  <pp-custom-field-cost
    ppTooltip
    [ppTitle]="
      ppCustomField.subValuesActive
        ? ('prompt_bulk_changes_subfields_edit_disabled' | ppTranslate)
        : ''
    "
    [ppCanEdit]="!ppCustomField.subValuesActive"
    [ppWorkspaceId]="ppCustomField.workspaceId"
    [ppFieldId]="ppCustomField.id"
    [ppFieldLabel]="ppCustomField.label"
    ppFieldCurrencyCode="%"
    [ppFieldValue]="'' + ppCustomFields[ppFieldId]"
    [ppDecimalPlaces]="0"
    [ppNew]="false"
    (ppUpdate)="updatePercentage($event, ppCustomField)"
  ></pp-custom-field-cost>
</div>
