import { Component, EventEmitter, Input, Output } from '@angular/core';
import { catchError, of, takeUntil, tap } from 'rxjs';
import {
  ConfirmModalComponent,
  TConfirmModalParams,
} from 'src/app/project/components/confirm-modal/confirm-modal.component';
import { ModalService } from 'src/app/project/components/modal/modal.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIconPath } from 'src/app/project/shared/enums/icons.enum';
import { AccountUsageService } from '../../../../account-usage/account-usage.service';
import { AccountSitesArchiveModalComponent } from '../account-sites-archive-modal/account-sites-archive-modal.component';
import { AccountSitesService } from '../account-sites.service';
import { ArchiveSitesService } from '../archive-sites.service';

@Component({
  selector: 'pp-account-settings-sites-bulk-changes',
  templateUrl: './account-settings-sites-bulk-changes.component.html',
  styleUrls: ['./account-settings-sites-bulk-changes.component.scss'],
})
export class AccountSettingsSitesBulkChangesComponent {
  @Input() ppAccountId: string;
  @Input() ppUnarchivedSitesCount: number;
  @Output() ppUpdate = new EventEmitter<void>();
  private readonly destroy$ = new EventEmitter<void>();

  selectedSites: string[] = [];
  EIconPath = EIconPath;
  allArchived = false;
  allUnarchived = false;

  constructor(
    private accountSitesService: AccountSitesService,
    private translationPipe: TranslationPipe,
    private modalService: ModalService,
    private archiveSitesService: ArchiveSitesService,
    private accountUsageService: AccountUsageService,
  ) {}

  ngOnInit(): void {
    this.accountSitesService.selectedSitesIdsChange$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedSites) => {
        this.selectedSites = selectedSites;

        this.checkIfAllSelectedAreArchived();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  deselectAll(): void {
    this.accountSitesService.clearSelectedSites();
  }

  showArchiveSitesModal(): void {
    const modalData: TConfirmModalParams = {
      message: this.translationPipe.transform('archive_site_confirm'),
      heading: this.translationPipe.transform('archive_site'),
      boldText: true,
      extraPadding: true,
      confirmText: this.translationPipe.transform('archive'),
    };
    this.modalService.setData(modalData);

    this.modalService.showModal(ConfirmModalComponent, {
      onClose: (confirm: boolean) => {
        if (confirm) {
          this.archiveSitesService
            .archiveSites(this.ppAccountId, this.selectedSites, true)
            .pipe(
              tap(() => {
                this.handleArchiveSuccess();
              }),
              catchError(() => {
                return of();
              }),
            )
            .subscribe();
        }
      },
    });
  }

  showUnarchiveSitesModal(): void {
    const modalData = {
      accountId: this.ppAccountId,
      selectedSites: this.selectedSites,
      unarchivedSitesCount: this.ppUnarchivedSitesCount,
    };
    this.modalService.setData(modalData);

    this.modalService.showModal(AccountSitesArchiveModalComponent, {
      onClose: (confirm: boolean) => {
        if (confirm) {
          this.handleArchiveSuccess();
        }
      },
    });
  }

  private handleArchiveSuccess(): void {
    this.ppUpdate.emit();
    this.checkIfAllSelectedAreArchived();
    this.deselectAll();
  }

  private checkIfAllSelectedAreArchived(): void {
    if (!this.ppAccountId) {
      return;
    }

    this.accountUsageService
      .fetchAccountUsage(this.ppAccountId, false)
      .pipe(
        tap((response) => {
          const usage = response;

          let allArchived = true;
          let allUnarchived = true;

          this.selectedSites.forEach((siteId) => {
            const site = usage[siteId];

            if (site) {
              if (site.archived) {
                allUnarchived = false;
              } else {
                allArchived = false;
              }
            }
          });

          this.allArchived = allArchived;
          this.allUnarchived = allUnarchived;
        }),
      )
      .subscribe();
  }
}
