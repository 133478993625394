import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Modal, ModalService } from 'src/app/project/components/modal/modal.service';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { ArchiveSitesService } from '../../account/account-settings/account-settings-pages/account-settings-sites/archive-sites.service';
import { TWorkspacesById } from '../../workspace/workspace.model';
import { WorkspaceService } from '../../workspace/workspace.service';
import { TDeleteSiteModalData } from './delete-site-modal.model';

@Component({
  selector: 'pp-delete-site-modal',
  templateUrl: './delete-site-modal.component.html',
  styleUrls: ['./delete-site-modal.component.scss'],
})
export class DeleteSiteModalComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  private modal: Modal;

  processing = false;

  deleteSiteMessage = '';

  siteName: string;
  workspaceId: string;
  workspaces: TWorkspacesById;
  private accountId: string;

  constructor(
    private promptService: PromptService,
    private modalService: ModalService,
    private translationPipe: TranslationPipe,
    private workspaceService: WorkspaceService,
    private router: Router,
    private archiveSitesService: ArchiveSitesService,
  ) {}

  ngOnInit() {
    this.modal = this.modalService.getModal();
    this.workspaces = this.workspaceService.getWorkspaces();

    const modalData: TDeleteSiteModalData = this.modal.data;

    this.workspaceId = modalData.workspaceId;
    this.accountId = this.workspaces[this.workspaceId].accountId;
    this.siteName = this.workspaces[this.workspaceId].siteName;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  hideModal(): void {
    this.modalService.hideModal();
  }

  deleteSite(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    this.archiveSitesService
      .archiveSites(this.accountId, [this.workspaceId], false)
      .pipe(
        tap(() => {
          const prompt = this.translationPipe.transform('site_deleted');

          this.promptService.showSuccess(prompt);

          this.router.navigate(['/dashboard']);
          this.modalService.hideModal();
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }
}
