import { TCustomField } from 'src/app/project/modules/custom-fields/custom-fields.model';
import {
  COST_CENTRE_CF_NAME,
  EXPENDITURE_CF_NAME,
  NET_CF_NAME,
  TOTAL_CF_NAME,
  VAT_CF_NAME,
} from '../site-settings-integrations-custom-fields.consts';

export function sortVolyCustomFields(fields: TCustomField[]): TCustomField[] {
  const suffixOrder = [NET_CF_NAME, VAT_CF_NAME, TOTAL_CF_NAME];
  const specialLabels = [COST_CENTRE_CF_NAME, EXPENDITURE_CF_NAME];

  // Separate regular fields and special fields
  const regularFields = fields.filter((field) => !specialLabels.includes(field.label));
  const specialFields = fields.filter((field) => specialLabels.includes(field.label));

  // Sort regular fields
  regularFields.sort((a, b) => {
    const aParsed = parseLabel(a.label);
    const bParsed = parseLabel(b.label);

    // Fields without currency should come first
    const aHasCurrency = aParsed.currency ? 1 : 0;
    const bHasCurrency = bParsed.currency ? 1 : 0;

    if (aHasCurrency !== bHasCurrency) return aHasCurrency - bHasCurrency;

    // Compare currency order
    const currencyOrder =
      aParsed.currency && bParsed.currency ? aParsed.currency.localeCompare(bParsed.currency) : 0;
    if (currencyOrder !== 0) return currencyOrder;

    const suffixComparison =
      suffixOrder.indexOf(aParsed.suffix) - suffixOrder.indexOf(bParsed.suffix);
    return suffixComparison;
  });

  // Append special fields at the end
  const sortedFields = [...regularFields, ...specialFields];

  return sortedFields;
}

// Helper function to parse label
function parseLabel(label: string): { currency: string | null; suffix: string | null } {
  const parts = label.split(' ');
  const currency = parts[0] !== 'Invoice' ? parts[0] : null;
  const suffix = currency ? parts.slice(1).join(' ') : parts.join(' ');

  return { currency, suffix };
}
