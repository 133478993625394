<section class="subfieldDescription" [class.pointCF__input--focus]="focused">
  <input
    #input
    [(ngModel)]="ppValue"
    (ngModelChange)="onDescriptionChange($event)"
    class="subfieldDescription__input"
    [attr.disabled]="ppCanEdit ? null : true"
    [attr.readonly]="ppCanEdit ? null : true"
    [placeholder]="'subfield_description_placeholder' | ppTranslate"
    type="text"
    (blur)="onBlur()"
    (focus)="onFocus()"
    (keydown)="onKeydown($event)"
    (change)="updateField()"
    (keyup)="onKeyUp($event)"
  />
</section>
