<div class="decimals__container">
  <input
    [ppAutonumeric]="autonumericOptions"
    id="decimal__input"
    name="decimal__input"
    class="decimal__input copyReg"
    [(ngModel)]="ppValue"
    placeholder="1"
    (keydown.enter)="blurInput($event)"
    (ppFormatted)="updateValue($event)"
  />

  <div class="inputArrow__container">
    <div
      class="inputArrow__wrapper"
      [class.inputArrow__wrapper--disabled]="ppValue >= ppMaxValue"
      (click)="increaseValue()"
    >
      <img
        class="inputArrow inputArrow--up"
        [class.inputArrow__wrapper--disabled]="ppValue >= ppMaxValue"
        [src]="EIconPath.ARROW_DOWN_GRAY"
      />
    </div>

    <div
      class="inputArrow__wrapper"
      [class.inputArrow__wrapper--disabled]="ppValue <= 0"
      (click)="decreaseValue()"
    >
      <img
        class="inputArrow"
        [class.inputArrow__wrapper--disabled]="ppValue <= 0"
        [src]="EIconPath.ARROW_DOWN_GRAY"
      />
    </div>
  </div>
</div>
