import { Component, Inject, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { Store } from '@ngrx/store';
import { UpdatePointCustomField } from 'src/app/project/modules/points/points.actions';

import { TPoint } from 'src/app/project/modules/points/points.model';

import { SitePointFilterService } from 'src/app/project/modules/filters/site-point-filter.service';
import { PointsUpdateService } from 'src/app/project/modules/points/points-update.service';
import { PromptService } from '../../../../../components/prompt/prompt.service';
import { PointActivityService } from '../../point-timeline/point-activity.service';
import { PointFieldsService } from '../point-fields.service';

import { DOCUMENT } from '@angular/common';
import { TPointUpdate } from '@project/view-models';
import { of, Subject, timer } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { trimNumericInput } from 'src/app/core/helpers/trim-numeric-input';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { EIntegrationStatus } from 'src/app/project/modules/custom-fields/custom-fields.model';
import { TPointCustomField } from 'src/app/project/view-models/custom-field-response-model';
import { TAutoNumericOptions } from '../../../../../../core/helpers/create-autonumeric';
import { EIconPath } from '../../../../../shared/enums/icons.enum';
import { PointsService } from '../../../points.service';

@Component({
  selector: 'pp-point-fields-percentage',
  templateUrl: './point-fields-percentage.component.html',
  styleUrls: ['./point-fields-percentage.component.scss', '../point-fields.component.scss'],
})
export class PointFieldsPercentageComponent implements OnChanges, OnDestroy {
  @Input() ppWorkspaceId: string;
  @Input() ppPointId: string;
  @Input() ppFieldId: string;
  @Input() ppFieldLabel: string;
  @Input() ppField: TPointCustomField;
  @Input() ppNew: boolean;
  @Input() ppCanEdit: boolean;
  @Input() ppSubfieldsActive: boolean;

  private readonly destroy$ = new Subject<void>();

  error = false;
  value: string;
  EIconPath = EIconPath;
  EIntegrationStatus = EIntegrationStatus;

  autonumericOptions: TAutoNumericOptions = {
    modifyValueOnWheel: false,
    decimalPlaces: 0,
    decimalPlacesRawValue: 0,
    formulaMode: true,
    watchExternalChanges: true,
  };

  private cancelUpdateField$ = new Subject<void>();
  private updateFieldTimerMs = 500;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<{ points: TPoint[] }>,
    private promptService: PromptService,
    private pointFieldsService: PointFieldsService,
    private pointActivityService: PointActivityService,
    private sitePointFilterService: SitePointFilterService,
    private pointsUpdateService: PointsUpdateService,
    private translationPipe: TranslationPipe,
    private pointsService: PointsService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ppPointId) {
      const point: TPoint = this.pointsService.findPoint(this.ppFieldId);

      if (point && this.ppField) {
        this.ppField.value = point.customFieldsMap[this.ppFieldId]?.value;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  updateField(value: string): void {
    this.value = value;

    const _id = this.ppPointId;
    const fieldId = this.ppFieldId;
    const fieldValue = this.value;
    const workspaceId = this.ppWorkspaceId;

    this.cancelUpdateField$.next();

    timer(this.updateFieldTimerMs)
      .pipe(
        takeUntil(this.cancelUpdateField$),
        tap(() => {
          this.updateFieldDeferred(_id, fieldId, fieldValue, workspaceId);
        }),
      )
      .subscribe();
  }

  private updateFieldDeferred(
    _id: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    if (this.ppNew) {
      this.updateFieldForNewPoint(_id, fieldId, fieldValue, workspaceId);

      return;
    }

    const body: TPointUpdate = {
      customFieldsList: [
        {
          customFieldTemplateId: this.ppFieldId,
          value: trimNumericInput(fieldValue),
        },
      ],
    };

    this.pointsUpdateService
      .updatePointField(_id, body)
      .pipe(
        takeUntil(this.destroy$),
        tap((response) => {
          const promptText = this.translationPipe.transform('prompt_point_percentage_update');

          this.promptService.showSuccess(promptText);
          this.pointActivityService.refreshTimeline(workspaceId, _id);
          this.sitePointFilterService.filterPoints({ _keepScrollPosition: true });
        }),
        catchError((error) => {
          this.pointFieldsService.showUpdatePointFieldError(error);

          this.error = true;

          return of();
        }),
      )
      .subscribe();
  }

  private updateFieldForNewPoint(
    pointId: string,
    fieldId: string,
    fieldValue: string,
    workspaceId: string,
  ): void {
    this.store.dispatch(
      new UpdatePointCustomField({
        workspaceId: workspaceId,
        pointId: pointId,
        customFieldId: fieldId,
        customFieldValue: fieldValue ? trimNumericInput(fieldValue) : fieldValue,
      }),
    );
  }
}
