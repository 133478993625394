import { createElement } from 'src/app/core/helpers/dom';
import Tooltip from 'src/app/project/features/tooltip/Tooltip';
import { GET_TABLE } from '../../../table.ui.store';

export function createHeaderNameElement(_index: number, groupLevel: number): HTMLElement {
  const table = GET_TABLE();
  const item = table.items[_index];

  let classList = ['table__headerName'];

  classList.push(`table__headerName--${groupLevel}`);

  const element = createElement('div', {
    attrs: {
      class: classList.join(' '),
    },
    children: [item.context],
  });

  const nameTooltip = new Tooltip({
    options: {},
    title: item.context.toString(),
    mobileTooltip: false,
    trimTooltip: true,
    childTooltip: false,
    element: element,
    trimElement: element,
  });

  nameTooltip.createTooltip();

  return element;
}
