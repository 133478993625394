import { TAllUsers } from '@project/view-models';
import { EBasicField, EBasicFieldShort } from 'src/app/project/shared/enums/basic-fields-enums';
import { CustomFieldsService } from '../../custom-fields/custom-fields.service';
import { TPoint } from '../../points/points.model';
import { isPointFlagged } from '../../points/utils/is-flagged';
import { TColumn } from '../../site/site-table/columns/column.model';
import { TWorkspacesById } from '../../workspace/workspace.model';
import { fillAssigneesValue } from './fill-assignees-value';
import { fillCreatedValue } from './fill-created-value';
import { fillCustomFieldValue } from './fill-custom-field-value';
import { fillDescriptionValue } from './fill-description-value';
import { fillUpdatedValue } from './fill-updated-value';
import { formatPointFlag } from './format-point-flag';

export function fillColumnValue(
  column: TColumn & { timeline?: boolean },
  point: TPoint,
  workspaces: TWorkspacesById,
  users: TAllUsers,
  newArray: (string | number)[],
  exportType: string,
  customFieldsService: CustomFieldsService,
) {
  switch (column.name) {
    case EBasicField.FLAGGED:
    case EBasicFieldShort.FLAGGED:
      return isPointFlagged(point) ? formatPointFlag(exportType) : '-';
    case EBasicField.SITE:
      return point.workspaceRef ? workspaces[point.workspaceRef.id].siteName : '';
    case EBasicField.ACCOUNT:
      return point.workspaceRef ? workspaces[point.workspaceRef.id].accountName : '';
    case EBasicField.PRIORITY:
    case EBasicFieldShort.PRIORITY:
      return point.priority ? point.priority : '';
    case EBasicField.STATUS:
    case EBasicFieldShort.STATUS:
      return point.status ? point.status : '';
    case EBasicFieldShort.SEQUENCE_NUMBER:
      return point.sequenceNumber ? point.sequenceNumber.toString() : '';
    case EBasicField.TITLE:
      return `${point.title}`;
    case EBasicField.DESCRIPTION:
      return fillDescriptionValue(point);
    case EBasicField.CREATED:
      return fillCreatedValue(point);
    case EBasicField.UPDATED:
      return fillUpdatedValue(point);
    case EBasicField.CREATED_BY:
      return point.header?.createdBy ? point.header.createdBy.caption : '';
    case EBasicField.ASSIGNEES:
      return fillAssigneesValue(point, users);
    case EBasicField.TAGS:
      return point.tags.join(' ');
    default:
      if (point.customFieldsMap) {
        return fillCustomFieldValue({
          point,
          column,
          customFieldsService,
          newArray,
          exportType: exportType,
        });
      }
  }
  return '';
}
