import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PromptService } from 'src/app/project/components/prompt/prompt.service';
import { TranslationPipe } from 'src/app/project/features/translate/translation.pipe';
import { AddCustomFieldToFilterService } from 'src/app/project/modules/filters/filter-custom-fields/add-custom-field-to-filter.service';
import { SiteDataService } from 'src/app/project/modules/site/site-data.service';
import { TSiteResponse } from 'src/app/project/modules/workspace/site-response.model';
import { setChangedWorkspace } from 'src/app/project/modules/workspace/workspace';
import { AddCustomField } from 'src/app/project/modules/workspace/workspace.actions';
import { TWorkspace, TWorkspacesById } from 'src/app/project/modules/workspace/workspace.model';
import { logEventInGTAG } from 'src/app/project/services/analytics/google-analytics';
import {
  EGoogleEventCategory,
  EGoogleEventSettings,
} from 'src/app/project/services/analytics/google-analytics.consts';
import { ResponseErrorService } from '../../errors/response-error.service';
import { CustomFieldsService } from '../custom-fields.service';
import { TSelectedCustomFields } from './add-custom-field.model';

@Injectable({
  providedIn: 'root',
})
export class ImportCustomFieldsService {
  constructor(
    private translationPipe: TranslationPipe,
    private siteDataService: SiteDataService,
    private customFieldsService: CustomFieldsService,
    private promptService: PromptService,
    private addCustomFieldToFilterService: AddCustomFieldToFilterService,
    private responseErrorService: ResponseErrorService,
    private store: Store,
  ) {}

  importCustomFields(
    selectedCustomFields: TSelectedCustomFields,
    workspaceId: string,
    currentWorkspace: TWorkspace,
    selectedWorkspaces: TWorkspacesById,
  ): Observable<TSiteResponse> {
    const customFields = {};

    let promptText = this.translationPipe.transform('prompt_error');
    let numberOfFields = 0;

    Object.keys(selectedCustomFields).forEach((accountId) => {
      const workspaces = selectedCustomFields[accountId];

      Object.keys(workspaces).forEach((iteratedWorkspaceId) => {
        const workspace = workspaces[iteratedWorkspaceId];

        if (workspace.length > 0) {
          customFields[iteratedWorkspaceId] = [];

          workspace.forEach((customFieldId) => {
            customFields[iteratedWorkspaceId].push(customFieldId.toString());
          });

          numberOfFields += workspace.length;
        }
      });
    });

    return this.siteDataService.importCustomFields(currentWorkspace.workspaceId, customFields).pipe(
      tap((response) => {
        promptText = this.handleCustomFieldImportSuccess({
          numberOfFields,
          response,
          selectedWorkspaces,
          workspaceId,
          currentWorkspace,
        });
      }),
      catchError((error) => {
        return this.responseErrorService.handleRequestError(error);
      }),
    );
  }

  private handleCustomFieldImportSuccess({
    numberOfFields,
    response,
    selectedWorkspaces,
    workspaceId,
    currentWorkspace,
  }: {
    numberOfFields: number;
    response: TSiteResponse;
    selectedWorkspaces: TWorkspacesById;
    workspaceId: string;
    currentWorkspace: TWorkspace;
  }) {
    const promptText =
      numberOfFields > 1
        ? this.translationPipe.transform('prompt_field_import_multiple')
        : this.translationPipe.transform('prompt_field_import_singular');

    logEventInGTAG(EGoogleEventSettings.SETTINGS__CF__IMPORT, {
      event_category: EGoogleEventCategory.SETTINGS,
    });

    response.customFields.forEach((newCustomField) => {
      if (!selectedWorkspaces[workspaceId].customFields.includes(newCustomField.id.toString())) {
        this.customFieldsService.addWorkspaceCustomField(workspaceId, newCustomField);

        this.store.dispatch(
          new AddCustomField({
            workspaceId: workspaceId,
            customFieldId: newCustomField.id.toString(),
          }),
        );

        this.addCustomFieldToFilterService.addCustomFieldToFilter(newCustomField, workspaceId);
      }
    });

    setChangedWorkspace(currentWorkspace.workspaceId);
    this.promptService.showSuccess(promptText);
    return promptText;
  }
}
